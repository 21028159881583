import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import Canada from "../assets/images/newzealands.jpg";
import sydney from "../assets/images/sydney.jpg";
import canadaplace from "../assets/images/newzeland.jpg";
import Australiaplace from "../assets/images/viewbyaus.jpg";

const destinations = {
  Sydney: {
    backgroundImage: sydney,
    image: Australiaplace,
    description: "Sydney: Launchpad for your Australian career",
    packages: {
      Description:
        "Sydney is a melting pot of cultures, with a vibrant community that celebrates diversity through festivals, art, and cuisine. This cultural richness enhances creativity and fosters a welcoming atmosphere for professionals from various backgrounds.Sydney promotes a healthy work-life balance, with many companies offering flexible working arrangements and a strong emphasis on employee well-being. The city’s beautiful beaches and outdoor activities encourage residents to enjoy their leisure time.Sydney’s stunning natural landscapes, including its iconic harbor, beaches, and parks, provide a beautiful backdrop for both work and leisure. This connection to nature contributes to overall well-being and inspires creativity.",
    },

    Opportunity: [
      {
        title: "Caregiver",
        description:
          "Provide care for elderly or disabled individuals, assisting with daily activities and ensuring their well-being.",
      },
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or commercial spaces, ensuring a tidy and organized environment.",
      },
      {
        title: "Factory Worker/Production",
        description:
          "Work on production lines, ensuring smooth manufacturing operations and adhering to safety regulations.",
      },
      {
        title: "Warehouse Worker",
        description:
          "Manage inventory and logistics in warehouses, including packing, shipping, and organizing goods.",
      },
      {
        title: "Truck Driver",
        description:
          "Transport goods and materials over land using heavy vehicles, ensuring timely and safe delivery.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale or consumption, ensuring quality and safety standards are met.",
      },
      {
        title: "Cook/Kitchen Help",
        description:
          "Assist in food preparation and cooking, ensuring the kitchen operates smoothly and efficiently.",
      },
      {
        title: "Grocery Store Keeper",
        description:
          "Manage the day-to-day operations of a grocery store, including stocking, sales, and customer service.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding tools and techniques, ensuring structural integrity and quality.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures and fixtures made of wood and other materials.",
      },
      {
        title: "Electrician",
        description:
          "Install and repair electrical systems in buildings or machinery, ensuring safety and compliance with regulations.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring quality produce is collected for sale or distribution.",
      },
    ],
  },
  NewZealand: {
    backgroundImage: canadaplace,
    image: Canada,
    description:
      "Are you seeking a fulfilling career in a vibrant and welcoming nation? look no further than NewZealand! From breathtaking landscapes to a thriving job market, NewZealand offers a wealth of opportunities for individuals with ambition and a desire to make a difference.",
    packages: {
      Description:
        "New Zealand is renowned for its breathtaking landscapes, from majestic mountains to beautiful beaches. This close connection to nature fosters a lifestyle that values outdoor activities and a healthy work-life balance.New Zealanders prioritize work-life balance, often valuing time with family and outdoor pursuits. Many companies promote flexible working arrangements and a culture that encourages employees to take time off for personal well-being.New Zealand consistently ranks high for quality of life, with excellent healthcare, education, and a safe living environment. The relaxed lifestyle and low population density contribute to a sense of community and well-being.",
    },

    Opportunity: [
      {
        title: "Kindergarten",
        description:
          "Educate and care for children in a kindergarten setting, fostering their growth and development.",
      },
      {
        title: "Caregiver",
        description:
          "Provide care for elderly or disabled individuals, assisting with daily activities and ensuring their well-being.",
      },
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or hotels, ensuring a tidy and organized environment.",
      },
      {
        title: "Masseur",
        description:
          "Provide therapeutic massage services to clients, helping them relax and relieve muscle tension.",
      },
      {
        title: "Cook",
        description:
          "Prepare and cook meals, ensuring quality and presentation while following food safety standards.",
      },
      {
        title: "Kitchen Help",
        description:
          "Assist cooks and chefs in the kitchen, handling food preparation and maintaining cleanliness.",
      },
      {
        title: "Bartender",
        description:
          "Prepare and serve drinks to customers, maintaining a clean bar area and providing excellent customer service.",
      },
      {
        title: "Paver",
        description:
          "Install and repair paved surfaces such as roads, driveways, and sidewalks, ensuring durability and safety.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures and fixtures made of wood and other materials.",
      },
      {
        title: "Electrician",
        description:
          "Install and repair electrical systems in buildings or machines, ensuring safety and compliance with regulations.",
      },
      {
        title: "Plumber",
        description:
          "Install and repair piping systems in residential and commercial properties, ensuring proper water flow and sanitation.",
      },
      {
        title: "Meat Production",
        description:
          "Work in meat processing facilities, handling and preparing meat products while adhering to safety standards.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Clean and maintain industrial facilities, ensuring compliance with health and safety regulations.",
      },
      {
        title: "Construction Worker",
        description:
          "Assist in various construction tasks, including building, renovating, and maintaining structures.",
      },
      {
        title: "Sorter - Recyclable Materials",
        description:
          "Sort and categorize recyclable materials in recycling facilities to promote efficient processing.",
      },
      {
        title: "Fruit/Vegetable Packer",
        description:
          "Pack fruits and vegetables for distribution, ensuring quality and adherence to packaging standards.",
      },
      {
        title: "Furniture Production",
        description:
          "Manufacture and assemble furniture pieces, ensuring quality craftsmanship and design.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding tools and techniques, ensuring structural integrity and quality.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale or consumption, ensuring quality and safety standards are met.",
      },
      {
        title: "Cosmetic Production",
        description:
          "Assist in the manufacturing and packaging of cosmetic products, ensuring quality and compliance with regulations.",
      },
      {
        title: "Garden Equipment Production",
        description:
          "Manufacture and assemble garden tools and equipment, ensuring quality and functionality.",
      },
    ],
  },
};

const AsiaDestination = () => {
  const location = useLocation();
  const { state } = location;
  const defaultTab = state?.selectedTab || "CzechRepublic";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [expandedOpportunities, setExpandedOpportunities] = useState({}); // State to manage individual expanded descriptions

  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);

  const handleTabClick = (country) => {
    setActiveTab(country);
  };

  const toggleDescription = (index) => {
    setExpandedOpportunities((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the expanded state for the specific job
    }));
  };

  const renderOpportunities = () => {
    const opportunities = destinations[activeTab].Opportunity;

    return (
      <>
        {opportunities.map((opportunity, index) => (
          <li key={index} className="opportunity-item">
            <strong
              className="opportunity-title"
              onClick={() => toggleDescription(index)}
            >
              {opportunity.title}
            </strong>
            {expandedOpportunities[index] && (
              <p className="opportunity-description">
                {opportunity.description}
              </p>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: `url(${destinations[activeTab]?.backgroundImage})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting career opportunities around the world.
            Find out what makes each location unique and why it should be your
            next travel destination.
          </p>
        </div>
      </div>
      <div className="your-destination-container">
        <div className="tabs">
          {Object.keys(destinations).map((country) => (
            <button
              key={country}
              className={`tab-button ${activeTab === country ? "active" : ""}`}
              onClick={() => handleTabClick(country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="contents" ref={contentRef}>
          <div className="destination-header">
            <h2>{activeTab}</h2>
            <p className="description">{destinations[activeTab].description}</p>
          </div>
          <div className="package-container">
            <p>{destinations[activeTab].packages.Description}</p>
          </div>

          <div className="documents-container">
            <div>
              <h3>Opportunities</h3>
              <ul style={{ listStyleType: "square" }}>
                {renderOpportunities()}
              </ul>
            </div>
            <div className="destination-image">
              <img
                src={destinations[activeTab].image}
                alt={`${activeTab} destination`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsiaDestination;
