import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import CzechRepublic from "../assets/images/CzechRepublic.jpg";
import axios from "axios";

const YourDestination = () => {
  const location = useLocation();
  const { state } = location;
  const [destinations, setDestinations] = useState([]);
  const defaultTab = state?.selectedTab;
  const [activeTab, setActiveTab] = useState(defaultTab);
  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);
  useEffect(() => {
    CountryApi();
  }, []);
  const CountryApi = () => {
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/user/view-add",
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
    }).then((data) => {
      if (data.data.result === true) {
        setDestinations(data.data.list[0]);
      } else {
        console.log("not working");
      }
    });
  };
  const handleTabClick = (country) => {
    setActiveTab(country);
  };
  let baseUrl = "https://lunarsenterprises.com:6004/";
  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: CzechRepublic,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting career around the world. Find out what
            makes each location unique and why it should be your next travel
            spot.
          </p>
        </div>
      </div>
      <div className="your-destination-container">
        <div className="tabs">
          {Object.keys(destinations).map((country) => (
            <button
              key={country}
              className={`tab-button ${activeTab === country ? "active" : ""}`}
              onClick={() => handleTabClick(country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="contents" ref={contentRef}>
          <div className="destination-header">
            <h2>{activeTab}</h2>
            <p className="description">
              {destinations[activeTab]?.sd_description1}
            </p>
          </div>
          <div className="package-container">
            <p>{destinations[activeTab]?.packages.Description}</p>
          </div>

          <div className="documents-container">
            <div>
              <h3>Opportunities</h3>
              <ul style={{ listStyleType: "square" }}>
                {destinations[activeTab]?.Opportunity.map(
                  (opportunity, index) => (
                    <li key={index}>{opportunity}</li>
                  )
                )}
              </ul>
            </div>
            <div className="destination-image">
              {console.log(
                baseUrl + destinations[activeTab]?.s_image,
                "backgroundss"
              )}
              <img
                src={baseUrl + destinations[activeTab]?.s_image}
                alt={`${activeTab} destination`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourDestination;
