import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/styles/Navbar.css";
import globalinter from "../assets/images/global international.png";
import { ToastContainer } from "react-toastify";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };
  return (
    <>
      {/* <ToastContainer autoClose={3000} theme={"light"} /> */}
      <nav className="navbar container-fluid">
        <div className="container">
          <div className="navbar-brand">
            <img src={globalinter} alt="Global International" />
            <Link to="/" onClick={closeMenu}>
              Global Internationale
            </Link>
          </div>

          <div
            className={`navbar-toggle ${isOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>

          <div className={`navbar-links ${isOpen ? "active" : ""}`}>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
            <div className="dropdown">
              <Link to="/destination">Your Travel Guide</Link>
              <div className="dropdown-menu">
                <Link
                  to={{
                    pathname: "/Africa/Zimbabwe",
                    state: { selectedTab: "Zimbabwe" },
                  }}
                  onClick={closeMenu}
                >
                  Africa
                </Link>
                <Link
                  onClick={closeMenu}
                  to={{
                    pathname: "/America/Canada",
                    state: { selectedTab: "Canada" },
                  }}
                >
                  America
                </Link>
                <Link
                  onClick={closeMenu}
                  to={{
                    pathname: "/Antartica/France",
                    state: { selectedTab: "France" },
                  }}
                >
                  Antarctica
                </Link>
                <Link
                  onClick={closeMenu}
                  to={{
                    pathname: "/asia/Malaysia",
                    state: { selectedTab: "Malaysia" },
                  }}
                >
                  Asia
                </Link>
                <Link
                  onClick={closeMenu}
                  to={{
                    pathname: "/Australia/Sydney",
                    state: { selectedTab: "Sydney" },
                  }}
                >
                  Australia
                </Link>
                <Link
                  onClick={closeMenu}
                  to={{
                    pathname: "/destination/CzechRepublic",
                    state: { selectedTab: "CzechRepublic" },
                  }}
                >
                  Europe
                </Link>
              </div>
            </div>
            {/* <Link to="/service">Services</Link> */}
            <Link onClick={closeMenu} to="/about">
              About Us
            </Link>
            <Link onClick={closeMenu} to="/contact">
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
