import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import workaboard from "../assets/images/Republic.jpg";
import malta from "../assets/images/malta.jpg";
import poland from "../assets/images/poland.jpg";
import germany from "../assets/images/germany.jpg";
import CzechRepublic from "../assets/images/CzechRepublic.jpg";
import Maltaplace from "../assets/images/Maltaa.jpg";
import polandplace from "../assets/images/Polands.jpg";
import germanys from "../assets/images/germanys.jpg";

const destinations = {
  CzechRepublic: {
    backgroundImage: CzechRepublic,
    image: workaboard,
    description: "Czech Republic: Launchpad for your European career",
    packages: {
      Starting_salary: "1.25 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "Czechs are known for their friendliness and hospitality, creating a welcoming environment for expatriates and newcomers. This openness fosters a sense of community and belonging, which is beneficial for both personal and professional relationships.Many companies in the Czech Republic prioritize work-life balance, promoting reasonable working hours and flexibility. Employees often enjoy generous vacation policies, allowing them to recharge and enjoy leisure activities.The Czech Republic offers a high standard of living, with affordable healthcare, excellent public transport, and a rich social life. The combination of urban and rural settings allows for a balanced lifestyle, making it an attractive place to live and work.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free health insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "work permit in 45-60 days / visa in 3 months",
      "A lot of jobs opportunity",
      "Permanent residency after 5 year",
      "Citizenship after 10 years",
      "School for your kids will be for free",
      "you can go to every country from schengen zone(spain,Italy,Germany,Norway,France)",
      "Ideal European base who love to travel",
      "they have affordable living,lowest crime rates in europe",
      "Parental allowance :2280 AED per month",
      "Transportation to school paid by the government",
      "Health insurance: medical and dental treatment,medicines,hospital treatment,sick pay",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Caregiver",
        description:
          "Provide assistance and care for elderly or disabled individuals, helping with daily activities.",
      },
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or establishments, ensuring a tidy environment.",
      },
      {
        title: "Factory Worker/Production",
        description:
          "Work on production lines in factories, ensuring efficient manufacturing processes and quality control.",
      },
      {
        title: "Warehouse Worker",
        description:
          "Manage inventory and organize goods in warehouses, including packing and shipping items.",
      },
      {
        title: "Truck Driver",
        description:
          "Transport goods and materials over land using heavy vehicles, adhering to safety regulations.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale or consumption, ensuring quality and safety standards are met.",
      },
      {
        title: "Cook/Kitchen Help",
        description:
          "Assist in food preparation and cooking, ensuring the kitchen operates smoothly and efficiently.",
      },
      {
        title: "Grocery Store Keeper",
        description:
          "Manage the day-to-day operations of a grocery store, including stocking, sales, and customer service.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding tools and techniques, ensuring structural integrity and safety.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures made of wood and other materials, such as furniture and fixtures.",
      },
      {
        title: "Electrician",
        description:
          "Install and repair electrical systems in buildings or machines, ensuring safe and effective operations.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring quality produce is collected for sale.",
      },
    ],
  },
  //   backgroundImage: canadaplace,
  //   image: Canada,
  //   description:
  //     "Are you seeking a fulfilling career in a vibrant and welcoming nation? look no further than canada! From breathtaking landscapes to a thriving job market, canada offers a wealth of opportunities for individuals with ambition and a desire to make a difference.",
  //   packages: {
  //     Starting_salary: "1.80L-2Lakh ",
  //     Service_Charge: "9 Lakh Including Job Offer + Work permit + work visa",
  //     Total_installment: {
  //       Initial: "Initial payment-3L(processing starting)",
  //       second: "3L(work permit)",
  //       final: "3L(Embassy)",
  //     },
  //   },
  //   Benefits: [
  //     "Health Insurance",
  //     "Retirement Saving Plan",
  //     "Retirement Saving Plans",
  //     "Employee Assistance Programs",
  //     "Life Insurance",
  //     "Flexible Spending Accounts",
  //     "Training And Professional Development",
  //     "Employee Discounts",
  //     "Flexible work Arrangements",
  //     "Performance Bonuses",
  //     "Stock Options or Equity",
  //   ],
  //   Benefits_Moving: [
  //     "Safe Cities:Canadian cities such as vancouver,Toronto and Montreal consistently rank high in the list of the safest cities globally. Low crime rates contribute to an overall sense of safety among residents",
  //     "Clean Environment",
  //     "Healthcare system",
  //     "Excellent Educationak Opportunities",
  //     "Equality and social inclusion",
  //     "Stable Economy",
  //     "Natural Beauty",
  //     "Sustainable lifestyle",
  //     "Cultural Access",
  //     "Nature-Centric Living",
  //     "Sports and Recreation",
  //     "Social Care program: canada offers various social care programs, incluing support for families, the elderly, and those with low incomes",
  //   ],
  //   Documents: [
  //     "Updated Resume",
  //     "Education Certificates",
  //     "Passport(front and back)",
  //     "passport size photo",
  //   ],
  //   Opportunity: [
  //     "Kindergarten",
  //     "Caregiver",
  //     "Housekeeper",
  //     "Masseur",
  //     "Cook",
  //     "Kitchen Help",
  //     "Bartender",
  //     "Paver",
  //     "Carpenter",
  //     "Electrician",
  //     "Plumber",
  //     "Meat Production",
  //     "Industrial Cleaner",
  //     "Construction worker",
  //     "Sorter-Recyclable Materials",
  //     "Fruit/Vegetable Packer",
  //     "Furniture Production",
  //     "Welder",
  //     "Butcher",
  //     "Cosmetic Production",
  //     "Garden Equipment Production",
  //   ],
  // },
  Malta: {
    backgroundImage: Maltaplace,
    image: malta,
    description: "Connect the world, build your future: Careers in malta",
    packages: {
      Starting_salary: "1.10 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "Malta is known for its friendly and welcoming people, making it easy for expatriates to integrate into the community. The multicultural society fosters a sense of belonging and encourages collaboration among individuals from various backgrounds.Malta promotes a healthy work-life balance, with many companies offering flexible working hours and a supportive environment. The Mediterranean lifestyle encourages individuals to enjoy their leisure time, contributing to overall well-being.With stunning coastlines, clear waters, and a pleasant climate, Malta offers an array of outdoor activities, from water sports to hiking. This connection to nature enhances the quality of life for residents and provides a refreshing break from work.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free Insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "A lot of jobs opportunity in malta",
      "You cost of living, 22nd happiest country in the world",
      "You can go to every country from union schengen(Spain,Italy,Germany,Norway,France,etc)",
      "156 days of unemployment benefits",
      "Maternity benefits/Maternity leave benefits",
      "Children's allowance",
      "Injury benefits(Insurance)",
      "Sickness assistance benefits",
      "Grants, bonuses and personal growth",
      "Social contribution in malta are at your benefit",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining residential or commercial spaces to ensure a tidy environment.",
      },
      {
        title: "Waiters",
        description:
          "Serve food and beverages to customers in restaurants, ensuring excellent customer service and satisfaction.",
      },
      {
        title: "Chefs",
        description:
          "Prepare and cook a variety of dishes, overseeing kitchen operations and ensuring quality standards.",
      },
      {
        title: "Bartenders",
        description:
          "Mix and serve alcoholic and non-alcoholic beverages to customers, maintaining a clean and organized bar.",
      },
      {
        title: "Masseur",
        description:
          "Provide therapeutic massages to clients to relieve tension and promote relaxation and well-being.",
      },
      {
        title: "Kitchen Help",
        description:
          "Assist chefs in food preparation and kitchen operations, ensuring cleanliness and efficiency.",
      },
      {
        title: "Farm Worker",
        description:
          "Perform various tasks on farms, including planting, harvesting, and caring for crops and livestock.",
      },
      {
        title: "Factory Worker/Production",
        description:
          "Work in manufacturing environments, contributing to the production process and ensuring quality control.",
      },
      {
        title: "Cleaner",
        description:
          "Perform cleaning duties in various settings, ensuring spaces are hygienic and well-maintained.",
      },
      {
        title: "Warehouse Worker",
        description:
          "Manage inventory and logistics in warehouses, including packing, shipping, and organizing goods.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding techniques, ensuring structural integrity and safety in constructions.",
      },
      {
        title: "Electrician",
        description:
          "Install, maintain, and repair electrical systems in buildings or machinery, adhering to safety standards.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair wooden structures and fixtures, including furniture and cabinetry.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables, ensuring quality and freshness for sale and distribution.",
      },
      {
        title: "Delivery Driver",
        description:
          "Transport goods to customers, ensuring timely and safe delivery while adhering to traffic regulations.",
      },
      {
        title: "Truck Driver",
        description:
          "Operate heavy vehicles to transport goods over long distances, following all safety and regulatory protocols.",
      },
      {
        title: "Bus Driver",
        description:
          "Transport passengers along predetermined routes, ensuring safety and adherence to schedules.",
      },
      {
        title: "Construction Worker",
        description:
          "Perform various tasks on construction sites, assisting in building and renovation projects.",
      },
      {
        title: "Paver/Plumber",
        description:
          "Install and repair pavements or plumbing systems, ensuring proper function and durability.",
      },
      {
        title: "Machine Operator",
        description:
          "Operate machinery in manufacturing or production settings, ensuring quality output and safety.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Perform cleaning and maintenance in industrial settings, ensuring safe and sanitary conditions.",
      },
    ],
  },
  Poland: {
    backgroundImage: polandplace,
    image: poland,
    description:
      "Where rich history meets a booming economy! Are you ready to launch your career in a dynamic European nation with a strong sense of cultural heritage? ",
    packages: {
      Starting_salary: "1.10L-1.5Lakh (Basic Salary) OT extra",
      Service_Charge: "7 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-2.33L(processing starting)",
        second: "2.33L(work permit)",
        final: "2.33L(Embassy)",
      },
      Description:
        "Poland has a deep and diverse cultural heritage, reflected in its architecture, music, and traditions. Cities like Kraków, Warsaw, and Gdańsk showcase a mix of medieval and modern influences, offering a vibrant cultural landscape that enriches both personal and professional life.Poland has a growing focus on promoting work-life balance. Many companies, especially in larger cities, offer flexible working hours, remote work options, and employee wellness programs, which help maintain a healthy balance between professional and personal life.Compared to many Western European countries, Poland offers a relatively affordable cost of living. This makes it easier for professionals to enjoy a high quality of life, with access to good healthcare, housing, and public services.",
    },
    Benefits: [
      "Free Accomodation",
      "Free Transportation",
      "Free meal every day at work",
      "Free health insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "You will get TRC(Temporary Residence Card) after 6 months",
      "You will get Permanent residence in 2 years",
      "You can bring your family after 'TWO YEARS'",
      "You will get citizenship after 7 years for you and Your family",
      "A Lot of jobs opportunity all over Europe. School for your kids will be free(Preschool,primary school,high school,University)",
      "you can go to every country from Union schengen (spain,Italy,Germany,Norway,France)",
      "Average retirement pension in 2020 equals 2000 PLN-4000 PLN(depending on how many years you work)",
      "you will ge support from government 500 PLN monthly for each child",
      "After 2 years of employment, you are entitled for 20 days of paid annual leave at work",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Caregiver",
        description:
          "Provide care for elderly or disabled individuals, assisting with daily activities and ensuring their well-being.",
      },
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or commercial spaces, ensuring a tidy and organized environment.",
      },
      {
        title: "Factory Worker/Production",
        description:
          "Work on production lines, ensuring smooth manufacturing operations and adhering to safety regulations.",
      },
      {
        title: "Warehouse Worker",
        description:
          "Manage inventory and logistics in warehouses, including packing, shipping, and organizing goods.",
      },
      {
        title: "Truck Driver",
        description:
          "Transport goods and materials over land using heavy vehicles, ensuring timely and safe delivery.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale or consumption, ensuring quality and safety standards are met.",
      },
      {
        title: "Cook/Kitchen Help",
        description:
          "Assist in food preparation and cooking, ensuring the kitchen operates smoothly and efficiently.",
      },
      {
        title: "Grocery Store Keeper",
        description:
          "Manage the day-to-day operations of a grocery store, including stocking, sales, and customer service.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding tools and techniques, ensuring structural integrity and quality.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures and fixtures made of wood and other materials.",
      },
      {
        title: "Electrician",
        description:
          "Install and repair electrical systems in buildings or machinery, ensuring safety and compliance with regulations.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring quality produce is collected for sale or distribution.",
      },
    ],
  },
  Germany: {
    backgroundImage: germanys,
    image: germany,
    description: "Discover the scenic beauty of germany.",
    packages: {
      Starting_salary: "1.25 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "Germany is the largest economy in Europe and a global leader in various sectors, including engineering, manufacturing, automotive, and technology. Its economic strength offers numerous job opportunities for skilled professionals, both locally and internationally.Germany consistently ranks high for quality of life, offering excellent healthcare, education, and infrastructure. Cities like Munich, Berlin, and Frankfurt provide a comfortable living environment with a wide range of amenities and services.German workplaces typically prioritize work-life balance, with policies that encourage reasonable working hours and ample vacation time. This focus on well-being helps employees maintain a healthy balance between their professional and personal lives.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free health insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "work permit in 45-60 days / visa in 3 months",
      "A lot of jobs opportunity",
      "Permanent residency after 5 year",
      "Citizenship after 10 years",
      "School for your kids will be for free",
      "you can go to wvwry country from schengen zone(spain,Italy,Germany,Norway,France)",
      "Ideal European base who love to travel",
      "they have affordable living,lowest crime rates in europe",
      "Parental allowance :2280 AED per month",
      "Transportation to school paid by the government",
      "Health insurance: medical and dental treatment,medicines,hospital treatment,sick pay",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Caregiver",
        description:
          "Provide care for elderly or disabled individuals, assisting with daily activities and ensuring their well-being.",
      },
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or commercial spaces, ensuring a tidy and organized environment.",
      },
      {
        title: "Factory Worker/Production",
        description:
          "Work on production lines, ensuring smooth manufacturing operations and adhering to safety regulations.",
      },
      {
        title: "Warehouse Worker",
        description:
          "Manage inventory and logistics in warehouses, including packing, shipping, and organizing goods.",
      },
      {
        title: "Truck Driver",
        description:
          "Transport goods and materials over land using heavy vehicles, ensuring timely and safe delivery.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale or consumption, ensuring quality and safety standards are met.",
      },
      {
        title: "Cook/Kitchen Help",
        description:
          "Assist in food preparation and cooking, ensuring the kitchen operates smoothly and efficiently.",
      },
      {
        title: "Grocery Store Keeper",
        description:
          "Manage the day-to-day operations of a grocery store, including stocking, sales, and customer service.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding tools and techniques, ensuring structural integrity and quality.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures and fixtures made of wood and other materials.",
      },
      {
        title: "Electrician",
        description:
          "Install and repair electrical systems in buildings or machinery, ensuring safety and compliance with regulations.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring quality produce is collected for sale or distribution.",
      },
    ],
  },
};

const YourDestination = () => {
  const location = useLocation();
  const { state } = location;
  const defaultTab = state?.selectedTab || "CzechRepublic";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [expandedOpportunities, setExpandedOpportunities] = useState({}); // State to manage individual expanded descriptions

  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);

  const handleTabClick = (country) => {
    setActiveTab(country);
  };

  const toggleDescription = (index) => {
    setExpandedOpportunities((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the expanded state for the specific job
    }));
  };

  const renderOpportunities = () => {
    const opportunities = destinations[activeTab].Opportunity;

    return (
      <>
        {opportunities.map((opportunity, index) => (
          <li key={index} className="opportunity-item">
            <strong
              className="opportunity-title"
              onClick={() => toggleDescription(index)}
            >
              {opportunity.title}
            </strong>
            {expandedOpportunities[index] && (
              <p className="opportunity-description">
                {opportunity.description}
              </p>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: `url(${destinations[activeTab]?.backgroundImage})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting career opportunities around the world.
            Find out what makes each location unique and why it should be your
            next travel destination.
          </p>
        </div>
      </div>
      <div className="your-destination-container">
        <div className="tabs">
          {Object.keys(destinations).map((country) => (
            <button
              key={country}
              className={`tab-button ${activeTab === country ? "active" : ""}`}
              onClick={() => handleTabClick(country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="contents" ref={contentRef}>
          <div className="destination-header">
            <h2>{activeTab}</h2>
            <p className="description">{destinations[activeTab].description}</p>
          </div>
          <div className="package-container">
            <p>{destinations[activeTab].packages.Description}</p>
          </div>

          <div className="documents-container">
            <div>
              <h3>Opportunities</h3>
              <ul style={{ listStyleType: "square" }}>
                {renderOpportunities()}
              </ul>
            </div>
            <div className="destination-image">
              <img
                src={destinations[activeTab].image}
                alt={`${activeTab} destination`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourDestination;
