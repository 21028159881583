import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import workaboard from "../assets/images/mexicanchurch.jpg";
import Canada from "../assets/images/canadabuild.jpg";
import malta from "../assets/images/statue.jpg";
import CzechRepublic from "../assets/images/mexico.jpg";
import canadaplace from "../assets/images/canadaplace.jpg";
import Maltaplace from "../assets/images/bridge.jpg";

const destinations = {
  Canada: {
    backgroundImage: canadaplace,
    image: Canada,
    description:
      "Are you seeking a fulfilling career in a vibrant and welcoming nation? look no further than canada! From breathtaking landscapes to a thriving job market, canada offers a wealth of opportunities for individuals with ambition and a desire to make a difference.",
    packages: {
      Starting_salary: "1.80L-2Lakh ",
      Service_Charge: "9 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-3L(processing starting)",
        second: "3L(work permit)",
        final: "3L(Embassy)",
      },
      Description:
        "Canadian workplaces prioritize a healthy work-life balance. With policies that support flexible working hours, generous parental leave, and vacation time, employees can maintain personal well-being while achieving professional goals. This focus on balance contributes to higher job satisfaction and overall productivity.Many Canadian companies prioritize corporate social responsibility (CSR), engaging in ethical business practices and initiatives that benefit society and the environment. Businesses often participate in community service, sustainability efforts, and charitable contributions, enhancing their positive impact on communities.Canadians are known for their politeness and friendliness. This welcoming attitude extends to the workplace, creating a supportive environment where employees feel valued and respected.",
    },
    Benefits: [
      "Health Insurance",
      "Retirement Saving Plan",
      "Retirement Saving Plans",
      "Employee Assistance Programs",
      "Life Insurance",
      "Flexible Spending Accounts",
      "Training And Professional Development",
      "Employee Discounts",
      "Flexible work Arrangements",
      "Performance Bonuses",
      "Stock Options or Equity",
    ],
    Benefits_Moving: [
      "Safe Cities:Canadian cities such as vancouver,Toronto and Montreal consistently rank high in the list of the safest cities globally. Low crime rates contribute to an overall sense of safety among residents",
      "Clean Environment",
      "Healthcare system",
      "Excellent Educationak Opportunities",
      "Equality and social inclusion",
      "Stable Economy",
      "Natural Beauty",
      "Sustainable lifestyle",
      "Cultural Access",
      "Nature-Centric Living",
      "Sports and Recreation",
      "Social Care program: canada offers various social care programs, incluing support for families, the elderly, and those with low incomes",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Kindergarten",
        description:
          "Educate and care for young children, creating a safe and nurturing environment that promotes learning and development.",
      },
      {
        title: "Caregiver",
        description:
          "Provide assistance and care for individuals who are elderly, disabled, or in need of support with daily activities.",
      },
      {
        title: "Housekeeper",
        description:
          "Perform cleaning and maintenance tasks in homes or commercial spaces to ensure a tidy and hygienic environment.",
      },
      {
        title: "Masseur",
        description:
          "Provide therapeutic massage services to clients, helping to relieve stress and promote relaxation and well-being.",
      },
      {
        title: "Cook",
        description:
          "Prepare and cook meals in accordance with recipes, maintaining high standards of food safety and hygiene.",
      },
      {
        title: "Kitchen Help",
        description:
          "Assist chefs and cooks in food preparation, cooking, and maintaining cleanliness in the kitchen.",
      },
      {
        title: "Bartender",
        description:
          "Mix and serve alcoholic and non-alcoholic beverages, providing excellent customer service in bars or restaurants.",
      },
      {
        title: "Paver",
        description:
          "Install and repair pavement surfaces, including roads, driveways, and walkways, ensuring durability and safety.",
      },
      {
        title: "Carpenter",
        description:
          "Construct and repair structures made of wood and other materials, including furniture, cabinets, and buildings.",
      },
      {
        title: "Electrician",
        description:
          "Install, maintain, and repair electrical systems in homes, businesses, and industrial facilities.",
      },
      {
        title: "Plumber",
        description:
          "Install and repair plumbing systems, ensuring proper water flow and drainage in residential and commercial properties.",
      },
      {
        title: "Meat Production",
        description:
          "Process and package meat products, ensuring compliance with safety and quality standards.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Perform cleaning tasks in industrial settings, using specialized equipment to maintain a safe and clean work environment.",
      },
      {
        title: "Construction Worker",
        description:
          "Assist in building and construction projects, performing various tasks such as digging, lifting, and operating machinery.",
      },
      {
        title: "Sorter-Recyclable Materials",
        description:
          "Sort and categorize recyclable materials, ensuring proper processing and compliance with recycling regulations.",
      },
      {
        title: "Fruit/Vegetable Packer",
        description:
          "Pack and prepare fruits and vegetables for sale, ensuring quality and freshness.",
      },
      {
        title: "Furniture Production",
        description:
          "Manufacture and assemble furniture pieces, ensuring quality craftsmanship and attention to detail.",
      },
      {
        title: "Welder",
        description:
          "Use welding techniques to join metal parts and structures, ensuring strong and durable connections.",
      },
      {
        title: "Butcher",
        description:
          "Cut and prepare meat products for sale, ensuring adherence to hygiene and safety standards.",
      },
      {
        title: "Cosmetic Production",
        description:
          "Manufacture and package cosmetic products, ensuring quality and compliance with industry regulations.",
      },
      {
        title: "Garden Equipment Production",
        description:
          "Produce and assemble garden equipment, ensuring quality and functionality for end-users.",
      },
    ],
  },
  Mexico: {
    backgroundImage: CzechRepublic,
    image: workaboard,
    description: "Mexico: Launchpad for your American career",
    packages: {
      Starting_salary: "1.25 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "Mexico boasts a rich cultural heritage that blends Indigenous traditions with Spanish influences. This vibrant culture is celebrated through colorful festivals, traditional music, and dance, creating a lively atmosphere that fosters creativity and community.Mexican workplaces generally promote a balanced lifestyle, with many companies recognizing the importance of time off for family and personal life. This approach helps employees maintain well-being and satisfaction, which can enhance productivity.Mexicans are known for their hospitality and warmth. The friendly atmosphere makes it easy for newcomers to feel at home, facilitating integration into both the workplace and the local community.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free health insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "work permit in 45-60 days / visa in 3 months",
      "A lot of jobs opportunity",
      "Permanent residency after 5 year",
      "Citizenship after 10 years",
      "School for your kids will be for free",
      "you can go to every country from schengen zone(spain,Italy,Germany,Norway,France)",
      "Ideal European base who love to travel",
      "they have affordable living,lowest crime rates in europe",
      "Parental allowance :2280 AED per month",
      "Transportation to school paid by the government",
      "Health insurance: medical and dental treatment,medicines,hospital treatment,sick pay",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Caregiver",
        description:
          "Provide assistance and support to individuals who are elderly, disabled, or in need of help with daily living activities.",
      },
      {
        title: "Housekeeper",
        description:
          "Perform cleaning and maintenance tasks in homes or commercial properties, ensuring a tidy and hygienic environment.",
      },
      {
        title: "Factory worker/Production",
        description:
          "Work on production lines to manufacture goods, ensuring efficiency and quality in the manufacturing process.",
      },
      {
        title: "Warehouse worker",
        description:
          "Assist in the storage and distribution of goods, performing tasks such as picking, packing, and inventory management.",
      },
      {
        title: "Truck driver",
        description:
          "Transport goods and materials over long distances using heavy vehicles, ensuring timely delivery and adherence to safety regulations.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale, ensuring quality control and compliance with health standards.",
      },
      {
        title: "Cook/Kitchen help",
        description:
          "Assist in meal preparation and cooking, maintaining a clean and organized kitchen environment.",
      },
      {
        title: "Grocery store keeper",
        description:
          "Manage the daily operations of a grocery store, including stocking shelves, assisting customers, and handling transactions.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding techniques, ensuring strong and durable connections in various structures and components.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures made of wood and other materials, focusing on quality craftsmanship.",
      },
      {
        title: "Electrician",
        description:
          "Install, maintain, and repair electrical systems in residential, commercial, and industrial settings.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring the collection of high-quality produce for sale.",
      },
    ],
  },
  UnitedStates: {
    backgroundImage: Maltaplace,
    image: malta,
    description:
      "Connect the world, build your future: Careers in United States",
    packages: {
      Starting_salary: "1.10 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "The U.S. is known for its cultural diversity, with people from various backgrounds, ethnicities, and experiences contributing to the workforce. This rich tapestry of cultures fosters innovation and creativity, enhancing problem-solving and decision-making processes in the workplace.While the work culture can be demanding, many companies are increasingly recognizing the importance of work-life balance. Flexible working hours, remote work options, and generous leave policies are becoming more common, allowing employees to maintain a healthy balance between their personal and professional lives.The U.S. offers a robust job market with opportunities across various industries, including finance, healthcare, technology, and entertainment. This diversity in career options attracts top talent from around the world.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free Insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "A lot of jobs opportunity in malta",
      "You cost of living, 22nd happiest country in the world",
      "You can go to every country from union schengen(Spain,Italy,Germany,Norway,France,etc)",
      "156 days of unemployment benefits",
      "Maternity benefits/Maternity leave benefits",
      "Children's allowance",
      "Injury benefits(Insurance)",
      "Sickness assistance benefits",
      "Grants, bonuses and personal growth",
      "Social contribution in malta are at your benefit",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or commercial properties, ensuring a tidy and organized environment.",
      },
      {
        title: "Waiters",
        description:
          "Serve food and beverages to customers in restaurants or cafes, providing excellent customer service and ensuring a pleasant dining experience.",
      },
      {
        title: "Chefs",
        description:
          "Plan, prepare, and cook a variety of dishes, managing kitchen operations and ensuring quality and presentation of food.",
      },
      {
        title: "Bartenders",
        description:
          "Prepare and serve alcoholic and non-alcoholic beverages, interact with customers, and maintain cleanliness of the bar area.",
      },
      {
        title: "Masseur",
        description:
          "Provide massage therapy to clients to promote relaxation and alleviate muscle tension, tailored to individual needs.",
      },
      {
        title: "Kitchen help",
        description:
          "Assist chefs in food preparation, cooking, and maintaining a clean kitchen environment.",
      },
      {
        title: "Farm Worker",
        description:
          "Perform agricultural tasks such as planting, harvesting, and maintaining crops, ensuring quality production.",
      },
      {
        title: "Factory worker/Production",
        description:
          "Work on assembly lines to manufacture products, ensuring efficiency and adherence to safety standards.",
      },
      {
        title: "Cleaner",
        description:
          "Perform cleaning duties in various settings, ensuring cleanliness and hygiene are maintained.",
      },
      {
        title: "Warehouse worker",
        description:
          "Manage the storage and distribution of goods, performing tasks such as picking, packing, and inventory management.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding techniques, ensuring strong and durable connections in various structures and components.",
      },
      {
        title: "Electrician",
        description:
          "Install, maintain, and repair electrical systems in residential, commercial, and industrial settings.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures made of wood and other materials, focusing on quality craftsmanship.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring the collection of high-quality produce.",
      },
      {
        title: "Delivery Driver",
        description:
          "Transport goods and packages to various locations, ensuring timely and safe deliveries.",
      },
      {
        title: "Truck driver",
        description:
          "Drive heavy vehicles to transport goods and materials over long distances, ensuring adherence to traffic regulations.",
      },
      {
        title: "Bus Driver",
        description:
          "Operate buses to transport passengers along designated routes, ensuring safety and providing excellent customer service.",
      },
      {
        title: "Construction worker",
        description:
          "Assist in building structures, performing tasks such as digging, lifting, and carrying materials.",
      },
      {
        title: "Paver/Plumber",
        description:
          "Lay pavement for roads or manage plumbing systems in buildings, ensuring proper installation and maintenance.",
      },
      {
        title: "Machine Operator",
        description:
          "Operate machinery in manufacturing settings, ensuring efficient production and adherence to safety protocols.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Perform cleaning and maintenance tasks in industrial settings, ensuring compliance with safety and hygiene regulations.",
      },
    ],
  },
};

const AmericaDestination = () => {
  const location = useLocation();
  const { state } = location;
  const defaultTab = state?.selectedTab || "CzechRepublic";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [expandedOpportunities, setExpandedOpportunities] = useState({}); // State to manage individual expanded descriptions

  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);

  const handleTabClick = (country) => {
    setActiveTab(country);
  };

  const toggleDescription = (index) => {
    setExpandedOpportunities((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the expanded state for the specific job
    }));
  };

  const renderOpportunities = () => {
    const opportunities = destinations[activeTab].Opportunity;

    return (
      <>
        {opportunities.map((opportunity, index) => (
          <li key={index} className="opportunity-item">
            <strong
              className="opportunity-title"
              onClick={() => toggleDescription(index)}
            >
              {opportunity.title}
            </strong>
            {expandedOpportunities[index] && (
              <p className="opportunity-description">
                {opportunity.description}
              </p>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: `url(${destinations[activeTab]?.backgroundImage})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting career opportunities around the world.
            Find out what makes each location unique and why it should be your
            next travel destination.
          </p>
        </div>
      </div>
      <div className="your-destination-container">
        <div className="tabs">
          {Object.keys(destinations).map((country) => (
            <button
              key={country}
              className={`tab-button ${activeTab === country ? "active" : ""}`}
              onClick={() => handleTabClick(country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="contents" ref={contentRef}>
          <div className="destination-header">
            <h2>{activeTab}</h2>
            <p className="description">{destinations[activeTab].description}</p>
          </div>
          <div className="package-container">
            <p>{destinations[activeTab].packages.Description}</p>
          </div>

          <div className="documents-container">
            <div>
              <h3>Opportunities</h3>
              <ul style={{ listStyleType: "square" }}>
                {renderOpportunities()}
              </ul>
            </div>
            <div className="destination-image">
              <img
                src={destinations[activeTab].image}
                alt={`${activeTab} destination`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmericaDestination;
