import React from "react";
import "../assets/styles/InquiryForm.css";

function InquiryForm() {
  return (
    <div className="inquiry-form">
      <div className="container-row">
        <div className="containers white-bg">
          <h2>Our Mission</h2>
          <p>
            To empower individuals to achieve their dreams of studying abroad
            and pursuing rewarding careers internationally. We are committed to
            providing comprehensive consultancy services that guide our clients
            towards academic excellence and professional success, enriching
            their lives through transformative experiences and global
            opportunities. We believe that education and employment are powerful
            catalysts for personal and professional growth. Join us on this
            transformative journey and let us help you achieve your dreams of
            studying abroad and building a successful international career.
          </p>
        </div>
        <div className="containers black-bg">
          <h2>Supportive Guidance</h2>
          <p>
            From resume writing and interview preparation to visa assistance and
            relocation support, we provide comprehensive guidance at every step
            of the job search and relocation process.
          </p>
        </div>
      </div>
      <div className="container-row">
        <div className="containers black-bg">
          <h2>Global Network</h2>
          <p>
            We have developed strong relationships with employers and companies
            worldwide, giving us access to a diverse range of job opportunities
            in various industries and sectors.
          </p>
        </div>
        <div className="containers white-bg">
          <h2>Personalized Service</h2>
          <p>
            We understand that every job seeker is unique, which is why we offer
            personalized assistance tailored to individual needs and
            preferences.We are committed to upholding the highest standards of
            quality and excellence in everything we do.We believe that education
            and employment are powerful catalysts for personal and professional
            growth.Our team of experienced recruiters specializes in matching
            candidates with positions that align with their skills,
            qualifications, and career aspirations.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InquiryForm;
