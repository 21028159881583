import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import workaboard from "../assets/images/zimbawaplace.jpg";
import Canada from "../assets/images/egypt.jpg";
import malta from "../assets/images/kenyaplace.jpg";
import CzechRepublic from "../assets/images/Zimbabwe.jpg";
import canadaplace from "../assets/images/egyptian.jpg";
import Maltaplace from "../assets/images/Kenya.jpg";

const destinations = {
  Zimbabwe: {
    backgroundImage: CzechRepublic,
    image: workaboard,
    description: "Zimbabwe: Launchpad for your African career",
    packages: {
      Starting_salary: "1.25 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "The Czech Republic has a robust and stable economy,being one of the fastest-growing in Europe. The country is known for its skilled workforce and strategic location in Central Europe, making it an attractive destination for foreign investment.Czech culture places a strong emphasis on work-life balance. Employees enjoy reasonable working hours, ample vacation time, and social benefits, contributing to high job satisfaction.Numerous international corporations have set up operations in the Czech Republic, fostering a multicultural workplace environment that encourages collaboration and innovation.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free health insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "work permit in 45-60 days / visa in 3 months",
      "A lot of jobs opportunity",
      "Permanent residency after 5 year",
      "Citizenship after 10 years",
      "School for your kids will be for free",
      "you can go to every country from schengen zone(spain,Italy,Germany,Norway,France)",
      "Ideal European base who love to travel",
      "they have affordable living,lowest crime rates in europe",
      "Parental allowance :2280 AED per month",
      "Transportation to school paid by the government",
      "Health insurance: medical and dental treatment,medicines,hospital treatment,sick pay",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Construction Laborer",
        description:
          "Assist in building structures by performing various physical tasks on construction sites.",
      },
      {
        title: "Painter",
        description:
          "Apply paint, stain, or coatings to walls, buildings, and other surfaces.",
      },
      {
        title: "Plumber",
        description:
          "Install, repair, and maintain pipes, fixtures, and other plumbing systems in homes or businesses.",
      },
      {
        title: "Security Guard",
        description:
          "Monitor and protect property, enforce rules, and report incidents to maintain safety.",
      },
      {
        title: "Machine Operator",
        description:
          "Operate and monitor machinery in manufacturing or industrial settings to ensure smooth production processes.",
      },
      {
        title: "Waiter/Waitress",
        description:
          "Take orders, serve food and drinks, and ensure a pleasant dining experience for customers in a restaurant.",
      },
      {
        title: "Janitor",
        description:
          "Clean and maintain buildings, ensuring they are sanitary and presentable.",
      },
      {
        title: "Delivery Driver",
        description:
          "Transport goods, food, or packages to customers, ensuring timely and accurate deliveries.",
      },
      {
        title: "Receptionist",
        description:
          "Greet visitors, answer phone calls, and manage administrative tasks in an office or hotel.",
      },
      {
        title: "Warehouse Worker",
        description:
          "Receive, store, and dispatch goods in a warehouse, ensuring inventory is properly managed.",
      },
      {
        title: "Retail Sales Associate",
        description:
          "Assist customers with purchases, restock shelves, and ensure an organized store layout.",
      },
    ],
  },
  Egypt: {
    backgroundImage: canadaplace,
    image: Canada,
    description:
      "Are you seeking a fulfilling career in a vibrant and welcoming nation? look no further. From breathtaking landscapes to a thriving job market.",
    packages: {
      Starting_salary: "1.80L-2Lakh ",
      Service_Charge: "9 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-3L(processing starting)",
        second: "3L(work permit)",
        final: "3L(Embassy)",
      },
      Description:
        "Egypt has one of the largest economies in the Middle East and North Africa (MENA) region. The government has implemented several reforms to improve the business environment, attract foreign investment, and promote economic growth.Egypt is home to some of the world's most iconic historical sites, including the Pyramids of Giza, the Sphinx, and the temples of Luxor. This deep historical legacy attracts tourists and scholars alike, contributing to its cultural significance.Egyptian culture is a melting pot of influences from ancient civilizations, Islamic heritage, and Mediterranean traditions. This diversity is reflected in its art, music, literature, and architecture.",
    },
    Benefits: [
      "Health Insurance",
      "Retirement Saving Plan",
      "Retirement Saving Plans",
      "Employee Assistance Programs",
      "Life Insurance",
      "Flexible Spending Accounts",
      "Training And Professional Development",
      "Employee Discounts",
      "Flexible work Arrangements",
      "Performance Bonuses",
      "Stock Options or Equity",
    ],
    Benefits_Moving: [
      "Safe Cities:Canadian cities such as vancouver,Toronto and Montreal consistently rank high in the list of the safest cities globally. Low crime rates contribute to an overall sense of safety among residents",
      "Clean Environment",
      "Healthcare system",
      "Excellent Educationak Opportunities",
      "Equality and social inclusion",
      "Stable Economy",
      "Natural Beauty",
      "Sustainable lifestyle",
      "Cultural Access",
      "Nature-Centric Living",
      "Sports and Recreation",
      "Social Care program: canada offers various social care programs, incluing support for families, the elderly, and those with low incomes",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Kindergarten",
        description:
          "Care for and educate young children, helping them develop social, emotional, and learning skills.",
      },
      {
        title: "Caregiver",
        description:
          "Provide care and assistance for elderly or disabled individuals, helping them with daily activities.",
      },
      {
        title: "Housekeeper",
        description:
          "Clean and maintain private homes or hotels, ensuring a tidy and organized environment.",
      },
      {
        title: "Masseur",
        description:
          "Perform massages to relieve pain, reduce stress, and promote relaxation in clients.",
      },
      {
        title: "Cook",
        description:
          "Prepare and cook meals in a kitchen setting, ensuring food is served promptly and meets quality standards.",
      },
      {
        title: "Kitchen Help",
        description:
          "Assist cooks in food preparation and kitchen duties, keeping the kitchen clean and organized.",
      },
      {
        title: "Bartender",
        description:
          "Prepare and serve alcoholic and non-alcoholic drinks to customers in a bar or restaurant.",
      },
      {
        title: "Paver",
        description:
          "Install paving stones, asphalt, or concrete on roads, driveways, and walkways.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, repair, and install wooden structures, furniture, and frameworks.",
      },
      {
        title: "Electrician",
        description:
          "Install, maintain, and repair electrical systems in homes, buildings, and industrial settings.",
      },
      {
        title: "Plumber",
        description:
          "Install and repair water, gas, and sewage pipes, ensuring proper plumbing systems are in place.",
      },
      {
        title: "Meat Production",
        description:
          "Process, package, and inspect meat products to ensure they meet safety and quality standards.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Clean and maintain large industrial facilities, such as factories and warehouses, ensuring safety and hygiene.",
      },
      {
        title: "Construction Worker",
        description:
          "Perform various tasks on construction sites, such as building, digging, and assisting with heavy machinery.",
      },
      {
        title: "Sorter-Recyclable Materials",
        description:
          "Sort and separate recyclable materials to ensure proper processing and disposal.",
      },
      {
        title: "Fruit/Vegetable Packer",
        description:
          "Package fruits and vegetables for distribution, ensuring quality and freshness.",
      },
      {
        title: "Furniture Production",
        description:
          "Assemble and manufacture furniture, working with wood, metal, and other materials.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts together using welding techniques, ensuring strong and durable connections.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale, ensuring quality and safety standards are met.",
      },
      {
        title: "Cosmetic Production",
        description:
          "Assist in the production and packaging of cosmetic products, ensuring they meet quality guidelines.",
      },
      {
        title: "Garden Equipment Production",
        description:
          "Manufacture and assemble garden tools and equipment, ensuring quality and functionality.",
      },
    ],
  },
  Kenya: {
    backgroundImage: Maltaplace,
    image: malta,
    description: "Connect the world, build your future: Careers in Kenya",
    packages: {
      Starting_salary: "1.10 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "Kenya is one of the fastest-growing economies in Africa, driven by sectors like agriculture, technology, tourism, and manufacturing. The country's economic landscape is dynamic, with a focus on innovation and entrepreneurship.Kenya is home to over 40 ethnic groups, each with its own unique traditions, languages, and customs. This diversity enriches the cultural landscape, making it a vibrant tapestry of experiences.Kenya is renowned for its breathtaking landscapes and rich biodiversity. National parks and reserves, such as the Maasai Mara and Amboseli, attract tourists and contribute to the country’s economy through eco-tourism.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free Insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "A lot of jobs opportunity in malta",
      "You cost of living, 22nd happiest country in the world",
      "You can go to every country from union schengen(Spain,Italy,Germany,Norway,France,etc)",
      "156 days of unemployment benefits",
      "Maternity benefits/Maternity leave benefits",
      "Children's allowance",
      "Injury benefits(Insurance)",
      "Sickness assistance benefits",
      "Grants, bonuses and personal growth",
      "Social contribution in malta are at your benefit",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or establishments, ensuring a tidy environment.",
      },
      {
        title: "Waiters",
        description:
          "Serve food and beverages to customers in restaurants or cafes, ensuring a pleasant dining experience.",
      },
      {
        title: "Chefs",
        description:
          "Prepare and cook meals in restaurants or kitchens, often creating new recipes and managing kitchen staff.",
      },
      {
        title: "Bartenders",
        description:
          "Mix and serve drinks to customers, providing excellent service and maintaining the bar area.",
      },
      {
        title: "Masseur",
        description:
          "Provide therapeutic massages to clients, helping to relieve tension and promote relaxation.",
      },
      {
        title: "Kitchen Help",
        description:
          "Assist chefs and kitchen staff with food preparation, cleaning, and maintaining kitchen equipment.",
      },
      {
        title: "Farm Worker",
        description:
          "Perform various tasks on farms, including planting, harvesting, and caring for crops and livestock.",
      },
      {
        title: "Factory Worker/Production",
        description:
          "Work on production lines in factories, ensuring efficient manufacturing processes and quality control.",
      },
      {
        title: "Cleaner",
        description:
          "Maintain cleanliness in various environments, including offices, homes, and public spaces.",
      },
      {
        title: "Warehouse Worker",
        description:
          "Manage inventory and organize goods in warehouses, including packing and shipping items.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding techniques, ensuring structural integrity and safety.",
      },
      {
        title: "Electrician",
        description:
          "Install, maintain, and repair electrical systems and wiring in residential and commercial settings.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures made of wood and other materials, such as furniture and fixtures.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring quality produce is collected.",
      },
      {
        title: "Delivery Driver",
        description:
          "Transport goods and packages to various locations, ensuring timely and safe delivery.",
      },
      {
        title: "Truck Driver",
        description:
          "Drive heavy vehicles to transport goods over long distances, adhering to safety regulations.",
      },
      {
        title: "Bus Driver",
        description:
          "Operate buses to transport passengers on scheduled routes, ensuring their safety and comfort.",
      },
      {
        title: "Construction Worker",
        description:
          "Perform manual labor on construction sites, assisting with building and renovation projects.",
      },
      {
        title: "Paver/Plumber",
        description:
          "Install paving stones or asphalt on surfaces and manage plumbing systems for residential or commercial buildings.",
      },
      {
        title: "Machine Operator",
        description:
          "Operate machinery in various industries, ensuring efficient production and maintenance.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Clean and maintain large industrial facilities, ensuring compliance with health and safety standards.",
      },
    ],
  },
};

const AsiaDestination = () => {
  const location = useLocation();
  const { state } = location;
  const defaultTab = state?.selectedTab || "CzechRepublic";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [expandedOpportunities, setExpandedOpportunities] = useState({}); // State to manage individual expanded descriptions

  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);

  const handleTabClick = (country) => {
    setActiveTab(country);
  };

  const toggleDescription = (index) => {
    setExpandedOpportunities((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the expanded state for the specific job
    }));
  };

  const renderOpportunities = () => {
    const opportunities = destinations[activeTab].Opportunity;

    return (
      <>
        {opportunities.map((opportunity, index) => (
          <li key={index} className="opportunity-item">
            <strong
              className="opportunity-title"
              onClick={() => toggleDescription(index)}
            >
              {opportunity.title}
            </strong>
            {expandedOpportunities[index] && (
              <p className="opportunity-description">{opportunity.description}</p>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: `url(${destinations[activeTab]?.backgroundImage})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting career opportunities around the world. Find out what
            makes each location unique and why it should be your next travel destination.
          </p>
        </div>
      </div>
      <div className="your-destination-container">
        <div className="tabs">
          {Object.keys(destinations).map((country) => (
            <button
              key={country}
              className={`tab-button ${activeTab === country ? "active" : ""}`}
              onClick={() => handleTabClick(country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="contents" ref={contentRef}>
          <div className="destination-header">
            <h2>{activeTab}</h2>
            <p className="description">{destinations[activeTab].description}</p>
          </div>
          <div className="package-container">
            <p>{destinations[activeTab].packages.Description}</p>
          </div>

          <div className="documents-container">
            <div>
              <h3>Opportunities</h3>
              <ul style={{ listStyleType: "square" }}>
                {renderOpportunities()}
              </ul>
            </div>
            <div className="destination-image">
              <img
                src={destinations[activeTab].image}
                alt={`${activeTab} destination`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsiaDestination;
