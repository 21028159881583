import React from "react";
import "../assets/styles/CounselingSection.css"; // Create and customize this CSS file

function CounselingSection() {
  return (
    <div className="counseling-form-container">
      <h3>Book A Free Counselling Session</h3>
      <form className="counseling-form">
        <input type="text" placeholder="Name" name="name" required />
        <input type="tel" placeholder="Phone" name="phone" required />
        <input type="email" placeholder="Email" name="email" required />

        <select name="degree" required>
          <option value="" disabled selected>
            Which Kind of Work Do You Want To Pursue?
          </option>
          <option value="Doctor">Doctor</option>
          <option value="Developer">Developer</option>
          <option value="labour Worker">labour Worker</option>
        </select>

        <select name="country" required>
          <option value="" disabled selected>
            Which Country Are You Planning To Work In?
          </option>
          <option value="uk">United Kingdom</option>
          <option value="usa">United States</option>
          <option value="canada">Canada</option>
          <option value="australia">Australia</option>
          <option value="germany">Germany</option>
        </select>

        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default CounselingSection;
