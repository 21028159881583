import React from "react";
import "../assets/styles/CompanyInfo.css";
import "../assets/styles/Service.css";
import offices from "../assets/images/map.jpg";

function CompanyInfo() {
  return (
    <div>
      <div
        className="intross"
        style={{
          backgroundImage: `url(${offices})`,
          width: "100%",
        }}
      >
        <div className="intross-text">
          <h2>WELCOME TO</h2>
          <h1>Global Internationale</h1>
          <p>
            Begin your journey towards a rewarding international career with us.
            At Global Internationale, we connect talented individuals with
            exciting job prospects around the world. Join us and make your mark
            on the global stage.
          </p>
        </div>
      </div>
      <div className="company-info">
        <h2>About Our Company</h2>
        <p>
          We are committed to delivering exceptional abroad job consultancy
          services to our clients. Global Internationale Pvt. Ltd. is a leading
          abroad job consultancy dedicated to connecting ambitious professionals
          with top global opportunities. We specialize in providing personalized
          guidance, from resume building and interview coaching to visa
          assistance and job placement across various industries. Our
          experienced team is committed to helping you navigate the complexities
          of overseas employment, ensuring a smooth and successful transition to
          your dream job abroad. Start your global career journey with us today!
        </p>
        <p>
          Global Internationale is the perfect place for clients to begin their
          international career journey. With over 35 years of experience in the
          Global employeement Sector, 5 offices, and representing companies from
          around the world, Global Internationale is a trailblazer in the field
          of global career.
        </p>
      </div>
    </div>
  );
}

export default CompanyInfo;
