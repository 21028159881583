import React from "react";
import "../assets/styles/AboveFooter.css";
import { Link } from "react-router-dom";

const AboveFooter = () => {
  return (
    <div className="above-footer-container">
      <div className="above-footer-top">
        <div className="footer-column">
          <h3>Information</h3>
          <ul className={`navbar-linkss`}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            {/* <li>
              <Link to="/service">Services</Link>
            </li> */}
            <li>
              <Link to="/destination">Global Presence</Link>
            </li>
            {/* <li>Entrance Exam</li>
            <li>Documents</li> */}
            <li>
              <Link to="/Contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        {/* <div className="footer-column">
          <h3>Services</h3>
          <ul className={`navbar-linkss`}>
            <li>
              <Link to="/service">Career Counselling</Link>
            </li>
            <li>
              <Link to="/service">Admission Guidance</Link>
            </li>
            <li>
              <Link to="/service">Financial Aids & Assistance</Link>
            </li>
            <li>
              <Link to="/service">Training Sessions</Link>
            </li>
            <li>
              <Link to="/service">Visa Preparation</Link>
            </li>
            <li>
              <Link to="/service">Pre-Departure Guidance</Link>
            </li>
            <li>
              <Link to="/service">Forex Services</Link>
            </li>
            <li>
              <Link to="/service">Transport and Accommodation</Link>
            </li>
            <li>
              <Link to="/service">After Graduation Programmes</Link>
            </li>
          </ul>
        </div> */}
        <div className="footer-column">
          <h3>Countries</h3>
          <ul className={`navbar-linkss`}>
            <li>
              <Link
                to={{
                  pathname: "/destination/CzechRepublic",
                  state: { selectedTab: "CzechRepublic" },
                }}
              >
                Czech Republic
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/destination/Canada",
                  state: { selectedTab: "Canada" },
                }}
              >
                Canada
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/destination/Malta",
                  state: { selectedTab: "Malta" },
                }}
              >
                Malta
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/destination/Poland",
                  state: { selectedTab: "Poland" },
                }}
              >
                Poland
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/destination/Germany",
                  state: { selectedTab: "Germany" },
                }}
              >
                Germany
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/destination/Australia",
                  state: { selectedTab: "Australia" },
                }}
              >
                Australia
              </Link>
            </li>
          </ul>
        </div>
        {/* <div className="footer-column">
          <h3>ERP</h3>
          <ul>
            <li>Login</li>
            <li>Register</li>
            <li>Privacy Policy</li>
          </ul>
        </div> */}
      </div>

      <div className="above-footer-bottom">
        <div className="location-column">
          <h4>Eranakulam </h4>
          <h4>Trivandrum</h4>
          <h4>UAE</h4>
          {/* <p>
            Workafella, No. 504, 5th Floor,
            <br />
            Anna Salai, Teynampet,
            <br />
            Chennai – 600 018,
            <br />
            Tamil Nadu, India.
            <br />
            <strong>Phone:</strong> +91 90870 30120 / +91 89033 30120
          </p> */}
        </div>
        <div className="location-column">
          <h4>Trivandrum (H O)</h4>
          <p>
            GLOBAL INTERNATIONALE,
            <br />
            TVS TOWERS,
            <br />
            KOWDIAR P.O, -695003
            <br />
            THIRUVANANTHAPURAM, Kerala.
            <br />
            {/* <strong>Phone:</strong> +91 99420 30120 / +91 99421 30120 */}
          </p>
        </div>
        <div className="location-column">
          <h4>Nagercoil</h4>
          <h4>Chennai</h4>
          <h4>Bangalore</h4>
          {/* <p>
            No. 24, 1st Floor, SR Complex,
            <br />
            Opp to New Bus Stand, Trichy Road,
            <br />
            Thanjavur – 613 005,
            <br />
            Tamil Nadu, India.
            <br />
            <strong>Phone:</strong> +91 86088 30120 / +91 86089 30120
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default AboveFooter;
