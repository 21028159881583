import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/styles/Testimonials.css";
import axios from "axios";
import { toast } from "react-toastify";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [expanded, setExpanded] = useState({}); // State to track read more/less

  useEffect(() => {
    TestimonialsList();
  }, []);

  const TestimonialsList = () => {
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/list/testmonials",
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          setTestimonials(data.data.data);
        } else {
          toast.warning(data?.data?.message);
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };

  const toggleReadMore = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle between true/false for the testimonial
    }));
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonials">
      <h2>What Our Clients Say</h2>
      <Slider {...settings} className="testimonial-slider">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <p className="testimonial-feedback">
              {expanded[index]
                ? `"${testimonial.t_description}"`
                : `"${testimonial.t_description.slice(0, 100)}..."`}
            </p>
            <button
              className="read-more-btn"
              onClick={() => toggleReadMore(index)}
            >
              {expanded[index] ? "Read Less" : "Read More"}
            </button>
            <p className="testimonial-name">- {testimonial.t_name}</p>
            <p className="testimonial-location">{testimonial.t_place}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
