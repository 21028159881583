import React from "react";
import "../assets/styles/SocialMediaSidebar.css";
import whatapp from "../assets/images/logo.png";
import instagram from "../assets/images/instagram.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import UpArrow from "../assets/images/up-arrow-upload.png";

const SocialMediaSidebar = () => {
  return (
    <div className="sidebar">
      <a
        href="https://wa.me/9107736141870"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatapp} alt="WhatsApp" className="icon" />
      </a>
      <a
        href="https://www.instagram.com/globalinternationale/?igsh=aDB1c3ZzbTlpcno%3D"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={instagram} alt="Instagram" className="icon" />
      </a>
      <a
        href="https://facebook.com/globalinternational?mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={facebook} alt="Facebook" className="icon" />
      </a>
      <a href="https://x.com" target="_blank" rel="noopener noreferrer">
        <img src={twitter} alt="X" className="icon" />
      </a>
      <a href="#" className="scroll-up">
        <img src={UpArrow} alt="Scroll Up" className="icon" />
      </a>
    </div>
  );
};

export default SocialMediaSidebar;
