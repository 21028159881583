import React from "react";
import CompanyInfo from "../components/CompanyInfo";
import InquiryForm from "../components/InquiryForm";
import "../assets/styles/About.css";

function About() {
  return (
    <div className="about">
      <CompanyInfo />
      <InquiryForm />
    </div>
  );
}

export default About;
