import React from "react";
import "../assets/styles/Service.css";
import travelbag from "../assets/images/travelbag.png";
import money from "../assets/images/money.jpeg";
import departure from "../assets/images/departure.jpeg";
import training from "../assets/images/training.jpeg";
import visa from "../assets/images/visa.jpeg";
import accommodation from "../assets/images/accomodation.jpeg";
import visacouncil from "../assets/images/visacounsil.jpeg";

const services = [
  {
    image: travelbag,
    title: "Career Counselling",
    description:
      "At this stage, we do the primary profiling, collating personal information along with SSLC, HSC, IELTS/GRE/GMAT scores. Broad academic interests, financial capabilities, and other expectations are also mapped.",
  },
  {
    image: money,
    title: "Financial Planning",
    description:
      "We assist in financial planning, ensuring you have a clear understanding of costs involved and various funding options available for your education or career abroad.",
  },
  {
    image: departure,
    title: "Pre-departure Guidance",
    description:
      "Our pre-departure guidance covers essential aspects like travel arrangements, accommodation, and cultural orientation to ensure a smooth transition.",
  },
  {
    image: training,
    title: "Training Sessions",
    description:
      "We offer tailored training sessions to prepare you for the educational and cultural environment abroad, including language skills and professional etiquette.",
  },
  {
    image: visa,
    title: "Visa Preparation",
    description:
      "Our visa experts guide you through the application process, ensuring you have all necessary documents and meet the criteria for a successful application.",
  },
  {
    image: accommodation,
    title: "Transport & Accommodation",
    description:
      "We assist in arranging your transport and accommodation to ensure you have a safe and comfortable stay in your destination country.",
  },
];

const Service = () => {
  return (
    <div className="services-container">
      <div
        className="intross"
        style={{
          backgroundImage: `url(${visacouncil})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>What we do</h1>
          <p>
            We understand the meticulous preparation that is required for
            securing Job to international companies, the rigors of processing
            their visas, making travel arrangements, finding suitable
            accommodation, and putting support systems into place for their
            tenure of stay. We have evolved a highly efficient process of
            onboarding for the clients which include:
          </p>
        </div>
      </div>

      {services.map((service, index) => (
        <div
          className={`counseling-container ${
            index % 2 === 0 ? "image-right" : "image-left"
          }`}
          key={index}
        >
          <div className="image-section">
            <img
              src={service.image}
              alt={service.title}
              style={{ width: "650px" }}
            />
          </div>
          <div className="text-section">
            <div className="text-content">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Service;
