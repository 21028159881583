import React, { useState } from "react";
import "../assets/styles/ContactUs.css";
import contactus from "../assets/images/contactus.jpeg";
import axios from "axios";

import { Grid } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = ({ history }) => {
  const [formData, setFormData] = useState({
    name: "",
    phno: "",
    email: "",
    job: "",
    country: "",
    nearest_office: "",
    work_plan: "",
    counselling_type: "",
    work_level: "",
  });
  const [load, setLoad] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const AddContact = (e) => {
    setLoad(true);
    e.preventDefault();
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/contact-us",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
      data: formData,
    })
      .then((data) => {
        if (data?.data?.status === true) {
          // window.location.reload();
          history.push("/");
          setLoad(false);
        } else {
          setLoad(false);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  return (
    <>
      <ToastContainer autoClose={3000} theme={"light"} />
      <div className="services-container">
        <div
          className="intross"
          style={{
            backgroundImage: `url(${contactus})`,
            width: "100%",
          }}
        >
          <div className="intross-text">
            <h1>Contact us</h1>
            <p>
              Book a Consultation Call us right now to avail the best
              counselling from our team of experts and grab the opportunity to
              make your dreams come true
            </p>
          </div>
        </div>
        <div className="contact-container">
          <h2 className="contact-title">Get In Touch</h2>
          <p className="contact-description">
            Please fill in all data fields to ensure we can process your enquiry
            as quickly as possible (*required fields).
          </p>
          <form className="contact-form" onSubmit={AddContact}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phno">Mobile Number</label>
                <input
                  type="tel"
                  id="phno"
                  name="phno"
                  value={formData.phno}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="nearest_office">
                  Nearest Global Internationale Office*
                </label>
                <select
                  id="nearest_office"
                  name="nearest_office"
                  value={formData.nearest_office}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Office</option>
                  <option value="Trivandrum">Trivandrum </option>
                  <option value="Eranakulam">Eranakulam</option>
                  <option value="Nagercoil">Nagercoil </option>
                  <option value="Chennai">Chennai </option>
                  <option value="Bangalore ">Bangalore </option>
                  <option value="UAE">UAE </option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="country">Your preferred working Place*</label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Place</option>
                  <option value="Czech Republic">Czech Republic </option>
                  <option value="Canada">Canada</option>
                  <option value="Malta">Malta </option>
                  <option value="Poland">Poland </option>
                  <option value="Germany">Germany </option>
                  <option value="Australia">Australia </option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="work_plan">When do you plan to work?*</label>
                <select
                  id="work_plan"
                  name="work_plan"
                  value={formData.work_plan}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Time</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March </option>
                  <option value="April">April </option>
                  <option value="May">May </option>
                  <option value="June">June </option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September </option>
                  <option value="October">October </option>
                  <option value="November">November </option>
                  <option value="December">December </option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="counselling_type">
                  Preferred mode of counselling*
                </label>
                <select
                  id="counselling_type"
                  name="counselling_type"
                  value={formData.counselling_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Mode</option>
                  <option value="Telephone">Telephone </option>
                  <option value="Online">Online </option>
                  <option value="Offline">Offline </option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="work_level">Preferred work level*</label>
                <select
                  id="work_level"
                  name="work_level"
                  value={formData.work_level}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Level</option>
                  <option value="Grey collar">Grey collar </option>
                  <option value="Blue collar">Blue collar </option>
                  <option value="white collar">white collar </option>
                </select>
              </div>
            </div>
            <button
              disabled={load ? true : false}
              type="submit"
              className="submit-btn"
            >
              {load ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Grid
                    visible={true}
                    height="30"
                    width="30"
                    color="white"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass="grid-wrapper"
                  />
                </div>
              ) : (
                "Book Now"
              )}
            </button>
          </form>
        </div>
        <div className="location-container">
          <h2>Our Locations</h2>
          <div className="inner-container">
            <div className="text-container">
              GLOBAL INTERNATIONALE, TVS TOWERS, KOWDIAR P.O,
              THIRUVANANTHAPURAM, Kerala 695003 +91 077361 41870 Locate Us
            </div>
            <div className="text-container">
              <h4>Branches</h4>
              Trivandrum | Eranakulam |Nagercoil | Chennai | Bangalore | UAE
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
