import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import workaboard from "../assets/images/paris.jpg";
import Canada from "../assets/images/Argu.jpg";
import CzechRepublic from "../assets/images/france.jpg";
import canadaplace from "../assets/images/Aurgentinacathedral.jpg";

const destinations = {
  France: {
    backgroundImage: CzechRepublic,
    image: workaboard,
    description: "France: Launchpad for your Antartica career",
    packages: {
      Starting_salary: "1.25 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "France is renowned for its vibrant culture, which encompasses art, literature, fashion, and cuisine. This cultural richness provides a stimulating environment for creativity and innovation, making it an attractive place for professionals in creative industries.The French value work-life balance, often reflected in labor laws that promote reasonable working hours and generous vacation time. The concept of “la durée légale” sets a 35-hour workweek, allowing employees to enjoy leisure time and family life.French workplaces typically emphasize collaboration and teamwork. Employees are encouraged to share their ideas and contribute to discussions, fostering a sense of community and shared purpose.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free health insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "work permit in 45-60 days / visa in 3 months",
      "A lot of jobs opportunity",
      "Permanent residency after 5 year",
      "Citizenship after 10 years",
      "School for your kids will be for free",
      "you can go to every country from schengen zone(spain,Italy,Germany,Norway,France)",
      "Ideal European base who love to travel",
      "they have affordable living,lowest crime rates in europe",
      "Parental allowance :2280 AED per month",
      "Transportation to school paid by the government",
      "Health insurance: medical and dental treatment,medicines,hospital treatment,sick pay",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Caregiver",
        description:
          "Provide care and support for elderly or disabled individuals, assisting with daily living activities.",
      },
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or commercial spaces, ensuring a tidy and hygienic environment.",
      },
      {
        title: "Factory worker/Production",
        description:
          "Work on assembly lines or production facilities, operating machinery and ensuring the efficient manufacturing of products.",
      },
      {
        title: "Warehouse worker",
        description:
          "Manage inventory, perform packing and shipping duties, and assist with logistics in warehouse environments.",
      },
      {
        title: "Truck driver",
        description:
          "Transport goods and materials over long distances using heavy vehicles, ensuring timely deliveries and compliance with regulations.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products for sale, ensuring quality standards and proper hygiene practices are maintained.",
      },
      {
        title: "Cook/Kitchen help",
        description:
          "Assist in food preparation and cooking, maintaining kitchen cleanliness, and supporting chefs in various tasks.",
      },
      {
        title: "Grocery store keeper",
        description:
          "Manage day-to-day operations of a grocery store, including stocking shelves, assisting customers, and handling transactions.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding techniques, ensuring strong and durable connections for various structures.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures made of wood and other materials, including furniture and cabinetry.",
      },
      {
        title: "Electrician",
        description:
          "Install and maintain electrical systems in residential and commercial buildings, ensuring safety and compliance with codes.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms, ensuring quality produce is collected for market sale.",
      },
    ],
  },
  Argentina: {
    backgroundImage: canadaplace,
    image: Canada,
    description:
      "Are you seeking a fulfilling career in a vibrant and welcoming nation? look no further than Argentina! From breathtaking landscapes to a thriving job market, Argentina offers a wealth of opportunities for individuals with ambition and a desire to make a difference.",
    packages: {
      Starting_salary: "1.80L-2Lakh ",
      Service_Charge: "9 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-3L(processing starting)",
        second: "3L(work permit)",
        final: "3L(Embassy)",
      },
      Description:
        "The Argentine workforce is characterized by its diversity, with professionals from various backgrounds contributing to a rich exchange of ideas. This cultural mix enhances problem-solving and collaboration in the workplace, driving creativity and innovation.While work can be demanding, many companies in Argentina recognize the importance of work-life balance. Flexible working hours and an emphasis on personal time help employees maintain a healthy lifestyle, contributing to overall job satisfaction.",
    },
    Benefits: [
      "Health Insurance",
      "Retirement Saving Plan",
      "Retirement Saving Plans",
      "Employee Assistance Programs",
      "Life Insurance",
      "Flexible Spending Accounts",
      "Training And Professional Development",
      "Employee Discounts",
      "Flexible work Arrangements",
      "Performance Bonuses",
      "Stock Options or Equity",
    ],
    Benefits_Moving: [
      "Safe Cities:Canadian cities such as vancouver,Toronto and Montreal consistently rank high in the list of the safest cities globally. Low crime rates contribute to an overall sense of safety among residents",
      "Clean Environment",
      "Healthcare system",
      "Excellent Educationak Opportunities",
      "Equality and social inclusion",
      "Stable Economy",
      "Natural Beauty",
      "Sustainable lifestyle",
      "Cultural Access",
      "Nature-Centric Living",
      "Sports and Recreation",
      "Social Care program: canada offers various social care programs, incluing support for families, the elderly, and those with low incomes",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Kindergarten",
        description:
          "Educate and care for young children, creating a safe and nurturing environment that promotes learning and development.",
      },
      {
        title: "Caregiver",
        description:
          "Provide assistance and care for individuals who are elderly, disabled, or in need of support with daily activities.",
      },
      {
        title: "Housekeeper",
        description:
          "Perform cleaning and maintenance tasks in homes or commercial spaces to ensure a tidy and hygienic environment.",
      },
      {
        title: "Masseur",
        description:
          "Provide therapeutic massage services to clients, helping to relieve stress and promote relaxation and well-being.",
      },
      {
        title: "Cook",
        description:
          "Prepare and cook meals in accordance with recipes, maintaining high standards of food safety and hygiene.",
      },
      {
        title: "Kitchen Help",
        description:
          "Assist chefs and cooks in food preparation, cooking, and maintaining cleanliness in the kitchen.",
      },
      {
        title: "Bartender",
        description:
          "Mix and serve alcoholic and non-alcoholic beverages, providing excellent customer service in bars or restaurants.",
      },
      {
        title: "Paver",
        description:
          "Install and repair pavement surfaces, including roads, driveways, and walkways, ensuring durability and safety.",
      },
      {
        title: "Carpenter",
        description:
          "Construct and repair structures made of wood and other materials, including furniture, cabinets, and buildings.",
      },
      {
        title: "Electrician",
        description:
          "Install, maintain, and repair electrical systems in homes, businesses, and industrial facilities.",
      },
      {
        title: "Plumber",
        description:
          "Install and repair plumbing systems, ensuring proper water flow and drainage in residential and commercial properties.",
      },
      {
        title: "Meat Production",
        description:
          "Process and package meat products, ensuring compliance with safety and quality standards.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Perform cleaning tasks in industrial settings, using specialized equipment to maintain a safe and clean work environment.",
      },
      {
        title: "Construction Worker",
        description:
          "Assist in building and construction projects, performing various tasks such as digging, lifting, and operating machinery.",
      },
      {
        title: "Sorter-Recyclable Materials",
        description:
          "Sort and categorize recyclable materials, ensuring proper processing and compliance with recycling regulations.",
      },
      {
        title: "Fruit/Vegetable Packer",
        description:
          "Pack and prepare fruits and vegetables for sale, ensuring quality and freshness.",
      },
      {
        title: "Furniture Production",
        description:
          "Manufacture and assemble furniture pieces, ensuring quality craftsmanship and attention to detail.",
      },
      {
        title: "Welder",
        description:
          "Use welding techniques to join metal parts and structures, ensuring strong and durable connections.",
      },
      {
        title: "Butcher",
        description:
          "Cut and prepare meat products for sale, ensuring adherence to hygiene and safety standards.",
      },
      {
        title: "Cosmetic Production",
        description:
          "Manufacture and package cosmetic products, ensuring quality and compliance with industry regulations.",
      },
      {
        title: "Garden Equipment Production",
        description:
          "Produce and assemble garden equipment, ensuring quality and functionality for end-users.",
      },
    ],
  },
};

const AsiaDestination = () => {
  const location = useLocation();
  const { state } = location;
  const defaultTab = state?.selectedTab || "CzechRepublic";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [expandedOpportunities, setExpandedOpportunities] = useState({}); // State to manage individual expanded descriptions

  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);

  const handleTabClick = (country) => {
    setActiveTab(country);
  };

  const toggleDescription = (index) => {
    setExpandedOpportunities((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the expanded state for the specific job
    }));
  };

  const renderOpportunities = () => {
    const opportunities = destinations[activeTab].Opportunity;

    return (
      <>
        {opportunities.map((opportunity, index) => (
          <li key={index} className="opportunity-item">
            <strong
              className="opportunity-title"
              onClick={() => toggleDescription(index)}
            >
              {opportunity.title}
            </strong>
            {expandedOpportunities[index] && (
              <p className="opportunity-description">
                {opportunity.description}
              </p>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: `url(${destinations[activeTab]?.backgroundImage})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting career opportunities around the world.
            Find out what makes each location unique and why it should be your
            next travel destination.
          </p>
        </div>
      </div>
      <div className="your-destination-container">
        <div className="tabs">
          {Object.keys(destinations).map((country) => (
            <button
              key={country}
              className={`tab-button ${activeTab === country ? "active" : ""}`}
              onClick={() => handleTabClick(country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="contents" ref={contentRef}>
          <div className="destination-header">
            <h2>{activeTab}</h2>
            <p className="description">{destinations[activeTab].description}</p>
          </div>
          <div className="package-container">
            <p>{destinations[activeTab].packages.Description}</p>
          </div>

          <div className="documents-container">
            <div>
              <h3>Opportunities</h3>
              <ul style={{ listStyleType: "square" }}>
                {renderOpportunities()}
              </ul>
            </div>
            <div className="destination-image">
              <img
                src={destinations[activeTab].image}
                alt={`${activeTab} destination`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsiaDestination;
