import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../assets/styles/Carousel.css";
import Travel from "../assets/images/map data.jpg";
import Abroad from "../assets/images/globle.jpg";
import work from "../assets/images/Rendering.jpg";

function ImageCarousel() {
  return (
    <Carousel
      className="carousel-container"
      showThumbs={false}
      autoPlay
      infiniteLoop
      showIndicators={false}
      interval={3000}
      transitionTime={1000}
      showStatus={false}
      dynamicHeight={true}
      emulateTouch={true}
      swipeable={true}
    >
      <div>
        <img src={Travel} alt="Work Abroad 1" />
      </div>
      <div>
        <img src={Abroad} alt="Work Abroad 2" />
      </div>
      <div>
        <img src={work} alt="Work Abroad 3" />
      </div>
    </Carousel>
  );
}

export default ImageCarousel;
