import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/YourDestination.css";
import workaboard from "../assets/images/malasiyasku.jpg";
import Canada from "../assets/images/japangate.jpg";
import malta from "../assets/images/singapor.jpg";
import CzechRepublic from "../assets/images/malaysia.jpg";
import canadaplace from "../assets/images/japan.jpg";
import Maltaplace from "../assets/images/singapore.jpg";

const destinations = {
  Malaysia: {
    backgroundImage: CzechRepublic,
    image: workaboard,
    description: "Malaysia: Launchpad for your Asian career",
    packages: {
      Starting_salary: "1.25 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "Malaysia is a melting pot of cultures, with a rich blend of Malay, Chinese, Indian, and Indigenous influences. This diversity is celebrated through festivals, traditions, and cuisines, creating a vibrant cultural atmosphere that enriches the workplace.The Malaysian workforce is characterized by its inclusivity, with companies actively promoting diversity and equal opportunities. This fosters a collaborative work environment where various perspectives and ideas are valued.Malaysian workplaces often emphasize the importance of work-life balance, with flexible working hours and policies that support employee well-being. This approach contributes to higher job satisfaction and productivity.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free health insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "work permit in 45-60 days / visa in 3 months",
      "A lot of jobs opportunity",
      "Permanent residency after 5 year",
      "Citizenship after 10 years",
      "School for your kids will be for free",
      "you can go to every country from schengen zone(spain,Italy,Germany,Norway,France)",
      "Ideal European base who love to travel",
      "they have affordable living,lowest crime rates in europe",
      "Parental allowance :2280 AED per month",
      "Transportation to school paid by the government",
      "Health insurance: medical and dental treatment,medicines,hospital treatment,sick pay",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Caregiver",
        description:
          "Provide care and assistance to elderly or disabled individuals, ensuring their comfort and well-being.",
      },
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or establishments, ensuring a tidy environment.",
      },
      {
        title: "Factory worker/Production",
        description:
          "Work on production lines, performing various tasks to ensure smooth manufacturing processes.",
      },
      {
        title: "Warehouse worker",
        description:
          "Manage inventory and assist in the storage and distribution of goods within a warehouse.",
      },
      {
        title: "Truck driver",
        description:
          "Transport goods and materials over long distances, ensuring timely delivery.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat products, ensuring quality and adherence to safety standards.",
      },
      {
        title: "Cook/Kitchen help",
        description:
          "Assist in food preparation and cooking, maintaining kitchen cleanliness and efficiency.",
      },
      {
        title: "Grocery store keeper",
        description:
          "Oversee daily operations of a grocery store, including stocking shelves and assisting customers.",
      },
      {
        title: "Welder",
        description:
          "Join metal components using welding techniques, ensuring strong and durable connections.",
      },
      {
        title: "Carpenter",
        description:
          "Construct, install, and repair structures made from wood and other materials.",
      },
      {
        title: "Electrician",
        description:
          "Install and maintain electrical systems in residential, commercial, and industrial settings.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from fields, ensuring quality produce is collected for sale.",
      },
    ],
  },
  Japan: {
    backgroundImage: canadaplace,
    image: Canada,
    description:
      "Are you seeking a fulfilling career in a vibrant and welcoming nation? look no further than Japan! From breathtaking landscapes to a thriving job market, Japan offers a wealth of opportunities for individuals with ambition and a desire to make a difference.",
    packages: {
      Starting_salary: "1.80L-2Lakh ",
      Service_Charge: "9 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-3L(processing starting)",
        second: "3L(work permit)",
        final: "3L(Embassy)",
      },
      Description:
        "Japan is renowned for its deep-rooted cultural heritage, blending ancient traditions with modern innovation. From traditional arts like tea ceremonies and calligraphy to contemporary pop culture, Japan offers a diverse cultural landscape that inspires creativity and appreciation for the arts.Japanese culture places a high value on diligence, commitment, and teamwork. The strong work ethic promotes a sense of responsibility and dedication, resulting in high-quality output and a culture of continuous improvement, often referred to as kaizen .While Japan is known for its demanding work culture, there is a growing recognition of the importance of work-life balance. Many companies are adopting policies to reduce overtime and promote employee well-being, allowing individuals to maintain a healthier lifestyle.",
    },
    Benefits: [
      "Health Insurance",
      "Retirement Saving Plan",
      "Retirement Saving Plans",
      "Employee Assistance Programs",
      "Life Insurance",
      "Flexible Spending Accounts",
      "Training And Professional Development",
      "Employee Discounts",
      "Flexible work Arrangements",
      "Performance Bonuses",
      "Stock Options or Equity",
    ],
    Benefits_Moving: [
      "Safe Cities:Canadian cities such as vancouver,Toronto and Montreal consistently rank high in the list of the safest cities globally. Low crime rates contribute to an overall sense of safety among residents",
      "Clean Environment",
      "Healthcare system",
      "Excellent Educationak Opportunities",
      "Equality and social inclusion",
      "Stable Economy",
      "Natural Beauty",
      "Sustainable lifestyle",
      "Cultural Access",
      "Nature-Centric Living",
      "Sports and Recreation",
      "Social Care program: canada offers various social care programs, incluing support for families, the elderly, and those with low incomes",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Kindergarten",
        description:
          "Provide early childhood education and care to young children, fostering their development.",
      },
      {
        title: "Caregiver",
        description:
          "Assist individuals, especially the elderly or disabled, with daily activities and personal care.",
      },
      {
        title: "Housekeeper",
        description:
          "Perform cleaning and maintenance tasks in homes or commercial establishments, ensuring a tidy environment.",
      },
      {
        title: "Masseur",
        description:
          "Provide therapeutic massage services to clients to promote relaxation and alleviate pain.",
      },
      {
        title: "Cook",
        description:
          "Prepare and cook meals in restaurants or other food service establishments, ensuring quality and presentation.",
      },
      {
        title: "Kitchen Help",
        description:
          "Assist chefs and cooks in food preparation, maintaining cleanliness and organization in the kitchen.",
      },
      {
        title: "Bartender",
        description:
          "Prepare and serve alcoholic and non-alcoholic beverages, interacting with customers to provide a positive experience.",
      },
      {
        title: "Paver",
        description:
          "Install and repair paved surfaces such as roads, walkways, and patios, ensuring durability and aesthetics.",
      },
      {
        title: "Carpenter",
        description:
          "Construct and repair building frameworks and structures, often working with wood and other materials.",
      },
      {
        title: "Electrician",
        description:
          "Install and maintain electrical systems in residential, commercial, and industrial buildings.",
      },
      {
        title: "Plumber",
        description:
          "Install and repair piping systems for water, gas, and drainage in residential and commercial properties.",
      },
      {
        title: "Meat Production",
        description:
          "Process and prepare meat products for sale, ensuring quality and compliance with safety standards.",
      },
      {
        title: "Industrial Cleaner",
        description:
          "Perform cleaning duties in industrial settings, ensuring safe and hygienic working environments.",
      },
      {
        title: "Construction Worker",
        description:
          "Perform various tasks on construction sites, assisting in building, renovations, and maintenance projects.",
      },
      {
        title: "Sorter-Recyclable Materials",
        description:
          "Sort and categorize recyclable materials in facilities, ensuring proper processing and disposal.",
      },
      {
        title: "Fruit/Vegetable Packer",
        description:
          "Pack and prepare fruits and vegetables for distribution, ensuring quality and freshness.",
      },
      {
        title: "Furniture Production",
        description:
          "Manufacture and assemble furniture products, ensuring quality craftsmanship and durability.",
      },
      {
        title: "Welder",
        description:
          "Join metal components using welding techniques, ensuring strong and precise connections.",
      },
      {
        title: "Butcher",
        description:
          "Prepare and cut meat for sale, ensuring proper handling and adherence to safety standards.",
      },
      {
        title: "Cosmetic Production",
        description:
          "Manufacture and package cosmetic products, ensuring quality control and compliance with regulations.",
      },
      {
        title: "Garden Equipment Production",
        description:
          "Manufacture tools and equipment for gardening and landscaping, ensuring durability and functionality.",
      },
    ],
  },
  Singapore: {
    backgroundImage: Maltaplace,
    image: malta,
    description: "Connect the world, build your future: Careers in Singapore ",
    packages: {
      Starting_salary: "1.10 Lakh (Basic Salary) OT extra",
      Service_Charge: "4.5 Lakh Including Job Offer + Work permit + work visa",
      Total_installment: {
        Initial: "Initial payment-60k(processing starting)",
        second: "1.95L(work permit)",
        final: "1.95L(Embassy)",
      },
      Description:
        "Singapore is a vibrant multicultural hub, home to various ethnic groups, including Chinese, Malay, Indian, and others. This diversity is celebrated through festivals, traditions, and cuisines, creating a rich cultural tapestry that enhances workplace creativity and collaboration.While Singapore is known for its fast-paced work environment, many companies are increasingly recognizing the importance of work-life balance. Initiatives promoting flexible work arrangements and employee well-being are becoming more common.",
    },
    Benefits: [
      "Free Transportation",
      "Free regeneration meal every day",
      "Free Insurance",
      "Airport pick up",
      "5 Days working per week ",
      "8 hours working per day",
      "No holding passport",
      "No company ban",
    ],
    Benefits_Moving: [
      "A lot of jobs opportunity in malta",
      "You cost of living, 22nd happiest country in the world",
      "You can go to every country from union schengen(Spain,Italy,Germany,Norway,France,etc)",
      "156 days of unemployment benefits",
      "Maternity benefits/Maternity leave benefits",
      "Children's allowance",
      "Injury benefits(Insurance)",
      "Sickness assistance benefits",
      "Grants, bonuses and personal growth",
      "Social contribution in malta are at your benefit",
    ],
    Documents: [
      "Updated Resume",
      "Education Certificates",
      "Passport(front and back)",
      "passport size photo",
    ],
    Opportunity: [
      {
        title: "Housekeeper",
        description:
          "Responsible for cleaning and maintaining homes or commercial establishments, ensuring a tidy environment.",
      },
      {
        title: "Waiters",
        description:
          "Serve food and beverages to customers in restaurants and ensure an enjoyable dining experience.",
      },
      {
        title: "Chefs",
        description:
          "Plan and prepare meals in restaurants or other food establishments, managing kitchen staff and operations.",
      },
      {
        title: "Bartenders",
        description:
          "Prepare and serve drinks at bars or restaurants, mixing beverages according to recipes and customer preferences.",
      },
      {
        title: "Masseur",
        description:
          "Provide therapeutic massages to clients, promoting relaxation and alleviating physical tension.",
      },
      {
        title: "Kitchen help",
        description:
          "Assist chefs and kitchen staff with food preparation, cooking, and cleaning tasks to maintain a functional kitchen.",
      },
      {
        title: "Farm Worker",
        description:
          "Perform various tasks on farms, including planting, harvesting, and caring for crops and livestock.",
      },
      {
        title: "Factory worker/Production",
        description:
          "Work on production lines in factories, operating machinery and assembling products efficiently.",
      },
      {
        title: "Cleaner",
        description:
          "Perform cleaning duties in various settings, including homes, offices, and public spaces, to maintain cleanliness.",
      },
      {
        title: "Warehouse worker",
        description:
          "Manage inventory and perform various tasks in a warehouse, including packing, sorting, and shipping products.",
      },
      {
        title: "Welder",
        description:
          "Join metal parts using welding techniques, ensuring strong and accurate connections in various constructions.",
      },
      {
        title: "Electrician",
        description:
          "Install and maintain electrical systems in buildings, ensuring compliance with safety regulations and codes.",
      },
      {
        title: "Carpenter",
        description:
          "Construct and repair structures made of wood and other materials, including framing, furniture, and cabinetry.",
      },
      {
        title: "Fruit/Vegetable Picker",
        description:
          "Harvest fruits and vegetables from farms or orchards, ensuring quality produce is collected for sale.",
      },
      {
        title: "Delivery Driver",
        description:
          "Transport goods and products to customers or businesses, ensuring timely and safe delivery.",
      },
      {
        title: "Truck driver",
        description:
          "Drive heavy vehicles to transport goods over long distances, adhering to safety regulations and schedules.",
      },
      {
        title: "Bus Driver",
        description:
          "Operate buses to transport passengers along scheduled routes, ensuring safety and comfort.",
      },
      {
        title: "Construction worker",
        description:
          "Perform various tasks on construction sites, assisting in building, renovations, and infrastructure projects.",
      },
      {
        title: "Paver, plumber",
        description:
          "Install and repair paved surfaces and plumbing systems, ensuring quality and functionality in construction.",
      },
      {
        title: "Machine Operator",
        description:
          "Operate and maintain machinery in factories or production settings, ensuring efficiency and safety.",
      },
      {
        title: "Industrial cleaner",
        description:
          "Perform cleaning tasks in industrial settings, ensuring compliance with health and safety standards.",
      },
    ],
  },
};

const AsiaDestination = () => {
  const location = useLocation();
  const { state } = location;
  const defaultTab = state?.selectedTab || "CzechRepublic";
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [expandedOpportunities, setExpandedOpportunities] = useState({}); // State to manage individual expanded descriptions

  const introRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (introRef.current) {
      introRef.current.classList.add("fade-in");
    }
    if (contentRef.current) {
      contentRef.current.classList.add("slide-in");
    }
  }, [activeTab]);

  const handleTabClick = (country) => {
    setActiveTab(country);
  };

  const toggleDescription = (index) => {
    setExpandedOpportunities((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the expanded state for the specific job
    }));
  };

  const renderOpportunities = () => {
    const opportunities = destinations[activeTab].Opportunity;

    return (
      <>
        {opportunities.map((opportunity, index) => (
          <li key={index} className="opportunity-item">
            <strong
              className="opportunity-title"
              onClick={() => toggleDescription(index)}
            >
              {opportunity.title}
            </strong>
            {expandedOpportunities[index] && (
              <p className="opportunity-description">
                {opportunity.description}
              </p>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="your-destination">
      <div
        className="intros"
        ref={introRef}
        style={{
          backgroundImage: `url(${destinations[activeTab]?.backgroundImage})`,
          width: "100%",
        }}
      >
        <div className="intros-text">
          <h1>Discover Your Next Adventure</h1>
          <p>
            Explore the most exciting career opportunities around the world.
            Find out what makes each location unique and why it should be your
            next travel destination.
          </p>
        </div>
      </div>
      <div className="your-destination-container">
        <div className="tabs">
          {Object.keys(destinations).map((country) => (
            <button
              key={country}
              className={`tab-button ${activeTab === country ? "active" : ""}`}
              onClick={() => handleTabClick(country)}
            >
              {country}
            </button>
          ))}
        </div>
        <div className="contents" ref={contentRef}>
          <div className="destination-header">
            <h2>{activeTab}</h2>
            <p className="description">{destinations[activeTab].description}</p>
          </div>
          <div className="package-container">
            <p>{destinations[activeTab].packages.Description}</p>
          </div>

          <div className="documents-container">
            <div>
              <h3>Opportunities</h3>
              <ul style={{ listStyleType: "square" }}>
                {renderOpportunities()}
              </ul>
            </div>
            <div className="destination-image">
              <img
                src={destinations[activeTab].image}
                alt={`${activeTab} destination`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsiaDestination;
