import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../assets/styles/DestinationSelector.css";
import Republic from "../assets/images/Republic.jpg";
import Canada from "../assets/images/canadabuild.jpg";
import malta from "../assets/images/malta.jpg";
import poland from "../assets/images/poland.jpg";
import germany from "../assets/images/germany.jpg";
import sydney from "../assets/images/sydney.jpg";

const destinations = [
  {
    name: "CzechRepublic",
    imageUrl: Republic,
    path: "/destination/CzechRepublic",
    mainName: "Czech Republic",
  },
  {
    name: "Canada",
    imageUrl: Canada,
    path: "/America/Canada",
    mainName: "Canada",
  },
  {
    name: "Malta",
    imageUrl: malta,
    path: "/destination/Malta",
    mainName: "Malta",
  },
  {
    name: "Poland",
    imageUrl: poland,
    path: "/destination/Poland",
    mainName: "Poland",
  },
  {
    name: "Germany",
    imageUrl: germany,
    path: "/destination/Germany",
    mainName: "Germany",
  },
  {
    name: "Sydney",
    imageUrl: sydney,
    path: "/Australia/Sydney",
    mainName: "Australia",
  },
];

const DestinationSelector = () => {
  const history = useHistory();
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    setAnimationClass("animate");
  }, []);

  const handleDestinationClick = (path, name) => {
    history.push({
      pathname: path,
      state: { selectedTab: name },
    });
  };

  return (
    <div className="destination-selector">
      <h2>Choose Your Future Goals</h2>
      <div className="view-more" onClick={() => history.push("/destinations")}>
        View more
      </div>
      <div className="destination-cards">
        {destinations.map((destination, index) => (
          <div
            key={index}
            className={`destination-card ${
              index % 2 === 0
                ? `${animationClass}-right`
                : `${animationClass}-left`
            }`}
            onClick={() =>
              handleDestinationClick(destination.path, destination.name)
            }
          >
            <img src={destination.imageUrl} alt={destination.name} />
            <div className="destination-infos">
              <h3>{destination.mainName}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DestinationSelector;
