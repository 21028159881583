import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import YourDestination from "./components/YourDestination";
import Service from "./components/Service";
import ContactUs from "./components/ContactUs";
import SocialMediaSidebar from "./components/SocialMediaSidebar";
import AsiaDestination from "./components/AsiaDestination";
import AfricaDestination from "./components/AfricaDestination";
import AmericaDestination from "./components/AmericaDestination";
import AntarticaDestination from "./components/AntarticaDestination";
import Australia from "./components/Australia";
import Destination from "./pages/Destination";
import { ToastContainer } from "react-toast";

function App() {
  return (
    <Router>
      <div className="App">
        <ToastContainer delay={3000} />
        <Navbar />
        <SocialMediaSidebar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/destination/:country" component={YourDestination} />
          <Route path="/asia/:country" component={AsiaDestination} />
          <Route path="/Africa/:country" component={AfricaDestination} />
          <Route path="/America/:country" component={AmericaDestination} />
          <Route path="/Antartica/:country" component={AntarticaDestination} />
          <Route path="/Australia/:country" component={Australia} />
          <Route path="/about" component={About} />
          <Route path="/destination" component={YourDestination} />
          <Route path="/destinations" component={Destination} />
          <Route path="/service" component={Service} />
          <Route path="/Contact" component={ContactUs} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
