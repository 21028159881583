import React, { useEffect, useState } from "react";
import ImageCarousel from "../components/Carousel";
import CounselingSection from "../components/CounselingSection";
import DestinationSelector from "../components/DestinationSelector";
import "../assets/styles/Home.css";
import Testimonials from "../components/Testimonials";
import Services from "../components/Services";
import Footer from "../components/Footer";
import AboveFooter from "../components/AboveFooter";
import ReactModal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";

function Home() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phno: "",
    email: "",
    job: "",
    country: "",
  });
  useEffect(() => {
    setIsOpen(true);
  }, []);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  function closeModal() {
    setIsOpen(false);
  }
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/contact-us",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
      data: formData,
    })
      .then((response) => {
        if (response.data.status) {
          closeModal();
        } else {
          console.log("not working");
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // toast.error("An error occurred. Please try again.");
      });
  };
  return (
    <div className="home">
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="counseling-form-container">
          <button className="close-modal-button" onClick={closeModal}>
            &times;
          </button>

          <h3>Book A Free Counselling Session</h3>
          <form className="counseling-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              placeholder="Phone"
              name="phno"
              value={formData.phno}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <select
              name="job"
              value={formData.job}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Which Kind of Work Do You Want To Pursue?
              </option>
              <option value="Caregiver">Caregiver</option>
              <option value="Housekeeper">Housekeeper</option>
              <option value="Butcher">Butcher</option>
              <option value="Welder">Welder</option>
              <option value="Carpenter">Carpenter</option>
              <option value="Electrician">Electrician</option>
              <option value="Chefs">Chefs</option>
              <option value="Bartenders">Bartenders</option>
              <option value="Cleaner">Cleaner</option>
              <option value="Truck driver">Truck driver</option>
              <option value="Plumber">Plumber</option>
              <option value="Paver">Paver</option>
            </select>

            <select
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Which Country Are You Planning To Work In?
              </option>
              <option value="canada">Canada</option>
              <option value="czech_republic">Czech Republic</option>
              <option value="malta">Malta</option>
              <option value="australia">Australia</option>
              <option value="germany">Germany</option>
            </select>

            <button type="submit">Send</button>
          </form>
        </div>
      </ReactModal>

      <div className="carousel-wrapper">
        <ImageCarousel />
        {/* <CounselingSection /> */}
      </div>
      <section className="intro">
        <h2>Explore Opportunities International</h2>
        <p>
          Find the best jobs in your desired country and take the first step
          towards your global career.
        </p>
      </section>
      <DestinationSelector />
      {/* <Services /> */}
      <Testimonials />
      <AboveFooter />
      <Footer />
    </div>
  );
}

export default Home;
